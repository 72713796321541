import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/setup/use-store";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import { message, Modal, Spin } from "antd";
import { StoreState } from "../../../enum/StoreState";
import axios, { CancelToken } from "axios";
import I4castAlert from "../../../components/I4castAlert/I4castAlert";
import ForecastChartPanelEnvironmentalButtons from "../ForecastChartPanelEnvironmentalButtons/ForecastChartPanelEnvironmentalButtons";
import ForecastChartPanelMeteogram from "../ForecastChartPanelMeteogram/ForecastChartPanelMeteogram";
import ForecastChartPanelMap from "../ForecastChartPanelMap/ForecastChartPanelMap";

import { regionToMacroRegion } from "../../../util/validadePointInsidePolygon/getGridLimitsFromConfig";
import { MacroRegion } from "../../../enum/MacroRegion";
import { Box, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import iconWave from "../../../assets/wave.svg";
import ForecastChartPanelHeader from "../ForecastChartPanelHeader/ForecastChartPanelHeader";
import ForecastChartInsightTimeline from "../ForecastChartInsightTimeline/ForecastChartInsightTimeline";
import { getForecastSteps } from "./steps/ForecastJoySteps";
import { JoyrideStorage } from "../../../enum/JoyrideStorage";
import useListenStorageChange from "../../../hooks/useListenStorageChange";
import { VisualizationType } from "../../../enum/VisualizationType";
import useGetStationsWithSensorData from "./hooks/useGetStationsWithSensorData";
import { getSensorJoySteps } from "./steps/SensorJoySteps";
import { Step } from "react-joyride";
import InitialGuidedTutorial from "./components/InitialGuidedTutorial";
import GeoSpatialGuidedTutorial from "./components/GeoSpatialGuidedTutorial";
import SensorGuidedTutorial from "./components/SensorGuidedTutorial";
import ProbabilisticGuidedTutorial from "./components/ProbabilisticGuidedTutorial";
import MetOceanReportTutorial from "./components/MetOceanReportTutorial";
import { getEnvironmentalVariablesDefault } from "util/meteogramCharts/processSerie/common/getEnvironmentalVariablesDefault";

const ForecastChartPage: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      forecastChartMeteogramStore,
      regionStore,
      unitMeasurementStore,
      forecastChartProbabilisticStore,
      analyticsChartStore,
      requestCancellationStore,
    },
  } = useStores();
  const toast = useToast();
  const toastId = "toastInfoNoDataId";

  const [disableMenuItems, setDisableMenuItems] =
    useState<EnvironmentalType[]>();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<
    EnvironmentalType[]
  >([]);

  const [forecastSteps, setForecastSteps] = useState<Step[]>(
    getForecastSteps()
  );
  const [sensorJoySteps, setSensorJoySteps] = useState<Step[]>(
    getSensorJoySteps()
  );

  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPage",
  });

  const preventTranslationOnRender = useRef(true);

  useGetStationsWithSensorData(
    forecastChartPageStore,
    forecastChartMeteogramStore
  );

  useEffect(() => {
    if (
      forecastChartPageStore.stations.length &&
      forecastChartPageStore.selectedStation === 0
    ) {
      const stationId = forecastChartPageStore.stations[0].station_id;
      forecastChartPageStore.setSelectedStation(stationId);
    }
    forecastChartPageStore
      .getValidStationsForecastChartAPI(EnvironmentalType.WAVE_SEA)
      .then(() => {
        if (forecastChartPageStore.stationsWithDataPostProcessed?.length) {
          let showModal = localStorage.getItem("showWaveSpectraModal");

          if (!showModal) {
            showModal = "true";
            localStorage.setItem("showWaveSpectraModal", showModal);
          }

          //TODO: Melhorar esse modal
          if (
            showModal === "true" &&
            forecastChartPageStore.tabStateActivty === EnvironmentalType.WAVE
          )
            Modal.info({
              title:
                t("clickBlueMarkersInTheMap") +
                t("toDisplayForecastChartWaveSpectra"),

              icon: <img src={iconWave} alt="wave" />,

              okText: t("letsGo"),
              onOk: handleHideWaveSpectraModal,
            });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (forecastChartPageStore.tabStateActivty !== EnvironmentalType.WAVE) {
      return;
    }

    if (!forecastChartPageStore.stationsWithDataPostProcessed?.length) {
      return;
    }

    let showModal = localStorage.getItem("showWaveSpectraModal");

    if (!showModal) {
      showModal = "true";
      localStorage.setItem("showWaveSpectraModal", showModal);
    }

    if (
      showModal === "true" &&
      forecastChartPageStore.tabStateActivty === EnvironmentalType.WAVE
    )
      Modal.info({
        title:
          t("clickBlueMarkersInTheMap") +
          t("toDisplayForecastChartWaveSpectra"),

        icon: <img src={iconWave} alt="wave" />,

        okText: t("letsGo"),
        onOk: handleHideWaveSpectraModal,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.tabStateActivty, t]);

  useEffect(() => {
    forecastChartPageStore.setFavoriteInsightAlreadyLoaded(false);
    unitMeasurementStore.getUserUnitMeasurementApi();
    if (forecastChartPageStore.stations.length === 0) {
      forecastChartPageStore.setClickMapLatLng(undefined);
      forecastChartPageStore.setSelectedStation(0);
      forecastChartPageStore.getStationsForecastChartAPI(
        EnvironmentalType.WEATHER
      );
    }
  }, []);

  useEffect(
    () => {
      forecastChartPageStore.setClickMapPointHeatMap([]);

      if (forecastChartPageStore.regionId > 0) {
        if (
          regionToMacroRegion(forecastChartPageStore.regionId) ===
          MacroRegion.RIVER_RHINE_GERMANY
        ) {
          forecastChartPageStore.setTabStateActivity(
            EnvironmentalType.WATER_FLOW
          );
          setDefaultSelectedKeys([EnvironmentalType.WATER_FLOW]);
        }
      }
      if (
        regionStore.macroRegions.length === 1 &&
        forecastChartPageStore.regionId > 0
      ) {
        if (
          regionToMacroRegion(forecastChartPageStore.regionId) ===
          MacroRegion.RIVER_RHINE_GERMANY
        ) {
          setDisableMenuItems([
            EnvironmentalType.WAVE,
            EnvironmentalType.WEATHER,
          ]);
        } else if (
          regionToMacroRegion(forecastChartPageStore.regionId) ===
          MacroRegion.PORT_OF_PARANAGUA
        ) {
          setDisableMenuItems([EnvironmentalType.WATER_FLOW]);
        } else if (
          regionToMacroRegion(forecastChartPageStore.regionId) ===
          MacroRegion.PORT_OF_SINES
        ) {
          setDisableMenuItems([EnvironmentalType.WATER_FLOW]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forecastChartPageStore.regionId]
  );

  useEffect(() => {
    const forecastStepsData = getForecastSteps();
    setForecastSteps(forecastStepsData);

    const sensorStepsData = getSensorJoySteps();
    setSensorJoySteps(sensorStepsData);

    if (preventTranslationOnRender?.current) {
      preventTranslationOnRender.current = false;
      return;
    }

    if (
      VisualizationType.SENSOR === forecastChartMeteogramStore.visualizationType
    ) {
      analyticsChartStore.setActionCallGetMeasuredChart();
    } else {
      forecastChartPageStore.setActionCallMeteogramChart(
        !forecastChartPageStore.actionCallMeteogramChart
      );
    }

    forecastChartPageStore.setStationInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const controller = new AbortController();
    if (!forecastChartPageStore.clickMapLatLng) {
      forecastChartMeteogramStore.setOpenMeteogram(false);
    }

    forecastChartPageStore.updateMacroRegionOrigin(
      forecastChartPageStore.clickMapLatLng,
      regionStore.macroRegions
    );

    forecastChartMeteogramStore.setOpenMeteogram(true);
    const pastDate = forecastChartMeteogramStore.selectForecastDateAvailable;
    const { macroRegionOrigin } = forecastChartPageStore;

    const isRhineRegion = macroRegionOrigin === MacroRegion.RIVER_RHINE_GERMANY;

    if (isRhineRegion || pastDate) {
      if (pastDate) {
        openMeteogramChart(source.token, pastDate.toUTC().toISO());
      } else {
        openMeteogramChart(source.token);
      }
    } else {
      openMeteogramChartMultipoints(controller);
    }

    const isVattenfall =
      forecastChartPageStore.macroRegionOrigin &&
      forecastChartPageStore.vattenfallRegions.includes(
        forecastChartPageStore.macroRegionOrigin
      );
    const isWeather =
      forecastChartPageStore.tabStateActivty === EnvironmentalType.WEATHER;

    if (isVattenfall && isWeather) {
      forecastChartMeteogramStore.setSelectedEnvironmentalVariables(
        getEnvironmentalVariablesDefault(
          EnvironmentalType.WEATHER,
          isVattenfall
        )
      );
    }

    return () => {
      source.cancel("Request cancelled");
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forecastChartMeteogramStore.selectForecastDateAvailable,
    forecastChartPageStore.actionCallMeteogramChart,
    regionStore.macroRegions,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    if (
      VisualizationType.SENSOR === forecastChartMeteogramStore.visualizationType
    ) {
      const coords = getCoordinatesIfValid();
      if (!coords) return;

      forecastChartPageStore.resetAllCharts();

      const selectedSensorMarker = isSensorMarkerSelected();

      if (selectedSensorMarker) {
        getMeasuredChart(controller);
      } else if (
        forecastChartPageStore.stateStationsWithSensorData === StoreState.DONE
      ) {
        getForecastChart(controller);
      }
    }
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analyticsChartStore.actionCallGetMeasuredChart,
    forecastChartMeteogramStore.selectForecastDateAvailable,
  ]);

  useEffect(() => {
    const controller = new AbortController();

    const coords = getCoordinatesIfValid();
    if (!coords) return;

    const { macroRegionOrigin } = coords;

    if (!macroRegionOrigin) {
      forecastChartMeteogramStore.setState(StoreState.DONE);
      forecastChartMeteogramStore.setIsChartDataEmpty(true);
      forecastChartProbabilisticStore.setIsChartDataEmpty(true);
      analyticsChartStore.setIsChartDataEmpty(true);
      forecastChartProbabilisticStore.setShowNoProbabilisticDataMessage(false);
      return;
    }

    forecastChartMeteogramStore.setIsChartDataEmpty(false);
    analyticsChartStore.setIsChartDataEmpty(false);
    forecastChartProbabilisticStore.setIsChartDataEmpty(false);

    const selectedSensorMarker = isSensorMarkerSelected();

    const getChartFunction: {
      [key in VisualizationType]: (signal: AbortController) => void;
    } = {
      [VisualizationType.FORECAST]: getForecastChart,
      [VisualizationType.PROBABILISTIC]: getProbabilisticChart,
      [VisualizationType.SENSOR]: selectedSensorMarker
        ? getMeasuredChart
        : getForecastChart,
    };

    const getChart =
      getChartFunction[forecastChartMeteogramStore.visualizationType];

    if (getChart) {
      getChart(controller);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.actionCallMapClick]);

  useEffect(() => {
    if (
      !toast.isActive(forecastChartPageStore.toastNoStationWithSensorId) &&
      forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR &&
      forecastChartPageStore.messageToastNoStationWithSensor
    ) {
      toast({
        id: forecastChartPageStore.toastNoStationWithSensorId,
        title: forecastChartPageStore.messageToastNoStationWithSensor,
        status: "info",
        position: "top",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartPageStore.messageToastNoStationWithSensor]);

  const handleHideWaveSpectraModal = () => {
    localStorage.setItem("showWaveSpectraModal", "false");
  };

  const openMeteogramChart = (cancelToken: CancelToken, create_at?: string) => {
    const stationId = forecastChartPageStore.selectedStation;
    const macroRegionOrigin = forecastChartPageStore.macroRegionOrigin;
    const station = forecastChartPageStore.getStationById(
      stationId,
      macroRegionOrigin!
    );

    if (station) {
      forecastChartPageStore.updateMacroRegionOrigin(
        {
          lat: station.station_lat,
          lng: station.station_lon,
        },
        regionStore.macroRegions
      );

      if (forecastChartPageStore.macroRegionOrigin) {
        forecastChartMeteogramStore.setIsChartDataEmpty(false);
        analyticsChartStore.setIsChartDataEmpty(false);
        forecastChartMeteogramStore.resetChartMeteogramData();
        forecastChartProbabilisticStore.setProbabilisticForecastData(null);
        if (
          VisualizationType.PROBABILISTIC ===
            forecastChartMeteogramStore.visualizationType &&
          forecastChartPageStore.probabilisticActivityVariable
        ) {
          forecastChartProbabilisticStore.setShowProbabilisticChart(true);
          forecastChartMeteogramStore.setShowMeteogram(false);
          forecastChartMeteogramStore.resetChartMeteogramData();
        } else if (
          VisualizationType.FORECAST ===
          forecastChartMeteogramStore.visualizationType
        ) {
          forecastChartMeteogramStore.setIsChartDataEmpty(false);
          forecastChartMeteogramStore.getChartMeteogramDataApi(
            station.station_id,
            forecastChartPageStore.tabStateActivty,
            cancelToken,
            forecastChartPageStore.macroRegionOrigin,
            create_at
          );
          forecastChartMeteogramStore.setVisibleMeteogramChart(true);
        }
      } else {
        message.info(t("forecastChartPage"));
      }
    } else {
      forecastChartMeteogramStore.setState(StoreState.DONE);
      forecastChartMeteogramStore.setIsChartDataEmpty(true);
    }
  };

  const openMeteogramChartMultipoints = (controller: AbortController) => {
    const coords = getCoordinatesIfValid();
    if (!coords) return;

    const { macroRegionOrigin } = coords;

    if (!macroRegionOrigin) {
      forecastChartMeteogramStore.setState(StoreState.DONE);
      forecastChartMeteogramStore.setIsChartDataEmpty(true);
      forecastChartProbabilisticStore.setIsChartDataEmpty(true);
      forecastChartProbabilisticStore.setShowNoProbabilisticDataMessage(false);
      return;
    }

    forecastChartMeteogramStore.setIsChartDataEmpty(false);

    forecastChartPageStore.resetAllCharts();

    if (
      VisualizationType.PROBABILISTIC ===
        forecastChartMeteogramStore.visualizationType &&
      forecastChartPageStore.probabilisticActivityVariable
    ) {
      requestCancellationStore.cancelAllRequests().then(() => {
        getProbabilisticChart(controller);
      });

      forecastChartProbabilisticStore.setShowProbabilisticChart(true);
      forecastChartMeteogramStore.setShowMeteogram(false);
    } else if (
      VisualizationType.FORECAST ===
      forecastChartMeteogramStore.visualizationType
    ) {
      getForecastChart(controller);

      forecastChartProbabilisticStore.setShowProbabilisticChart(false);
    }

    forecastChartMeteogramStore.setVisibleMeteogramChart(true);
  };

  const getForecastChart = (controller?: AbortController) => {
    const latLng = forecastChartPageStore.clickMapLatLng;
    const macroRegionOrigin = forecastChartPageStore.macroRegionOrigin;
    const stationId = forecastChartPageStore.selectedStation;

    forecastChartMeteogramStore.getEnvironmentalDataMeteogramNetCDFApi({
      latitude: latLng.lat,
      longitude: latLng.lng,
      environmental_type: forecastChartPageStore.tabStateActivty,
      macroRegionOrigin: macroRegionOrigin!,
      controller,
      stationId,
    });
    forecastChartMeteogramStore.getForecastUpdate(
      macroRegionOrigin!,
      forecastChartPageStore.tabStateActivty
    );

    if (
      VisualizationType.SENSOR === forecastChartMeteogramStore.visualizationType
    ) {
      if (
        !toast.isActive(toastId) &&
        forecastChartMeteogramStore.openMeteogram &&
        forecastChartPageStore.stateStationsWithSensorData === StoreState.DONE
      ) {
        toast({
          id: toastId,
          title: t("sensorForecastData"),
          status: "info",
          position: "top",
          isClosable: true,
        });
      }
    }
  };

  const getProbabilisticChart = (controller: AbortController) => {
    const latLng = forecastChartPageStore.clickMapLatLng;
    const macroRegionOrigin = forecastChartPageStore.macroRegionOrigin;
    const probabilisticActivityVariable =
      forecastChartPageStore.probabilisticActivityVariable;

    forecastChartProbabilisticStore.getProbabilisticForecastDataApi(
      {
        latitude: latLng.lat,
        longitude: latLng.lng,
        environmentalVariable: probabilisticActivityVariable!,
        macroregionOrigin: macroRegionOrigin!,
      },
      controller
    );
  };

  const getMeasuredChart = (controller?: AbortController) => {
    const selectedSensorMarker = isSensorMarkerSelected();

    const stationId = forecastChartPageStore.selectedStation;
    const regionId = forecastChartPageStore.regionId;
    analyticsChartStore.setIsChartDataEmpty(false);

    forecastChartMeteogramStore.setSelectedSensorMarker(selectedSensorMarker);

    const selectedPastDate =
      forecastChartMeteogramStore.selectForecastDateAvailable?.toUTC().toISO();

    analyticsChartStore.getMeasuredDataChartApi({
      environmentalType:
        selectedSensorMarker?.environmentalType ??
        forecastChartPageStore.tabStateActivty,
      stationId,
      regionId,
      createdAt: selectedPastDate,
      controller,
    });
  };

  const getCoordinatesIfValid = () => {
    const latLng = forecastChartPageStore.clickMapLatLng;

    if (!latLng) return false;

    if (!forecastChartPageStore.macroRegionOrigin) {
      forecastChartMeteogramStore.setState(StoreState.DONE);
      forecastChartMeteogramStore.setIsChartDataEmpty(true);
      return false;
    }

    const isLatLonValidGrid = forecastChartPageStore.validateLatLonGrid(
      latLng.lat,
      latLng.lng,
      forecastChartPageStore.macroRegionOrigin
    );

    if (!isLatLonValidGrid) {
      forecastChartPageStore.resetAllCharts();
      forecastChartMeteogramStore.setState(StoreState.DONE);
      forecastChartMeteogramStore.setIsChartDataEmpty(true);
      forecastChartProbabilisticStore.setShowNoProbabilisticDataMessage(false);
      return false;
    }

    return {
      latLng,
      macroRegionOrigin: forecastChartPageStore.macroRegionOrigin,
    };
  };

  const isSensorMarkerSelected = () => {
    const isSensorMarker = forecastChartPageStore.sensorMarkerData?.find(
      (s) =>
        s.region_id === forecastChartPageStore.regionId &&
        s.station_id === forecastChartPageStore.selectedStation
    );
    forecastChartMeteogramStore.setIsSensorMarker(!!isSensorMarker);

    return isSensorMarker;
  };

  const { localStorageValue: forecastLocalStorageValue } =
    useListenStorageChange(JoyrideStorage.FORECAST_RIDE_ON_LOCAL_STORAGE);

  const { localStorageValue: initialSensorLocalStorageValue } =
    useListenStorageChange(JoyrideStorage.INITIAL_SENSOR_RIDE_ON_LOCAL_STORAGE);

  const { localStorageValue: geoSpatialLocalStorageValue } =
    useListenStorageChange(JoyrideStorage.GEOSPATIAL_ON_LOCAL_STORAGE);

  const { localStorageValue: probabilisticLocalStorageValue } =
    useListenStorageChange(JoyrideStorage.PROBABILISTIC_ON_LOCAL_STORAGE);

  const { localStorageValue: sensorLocalStorageValue } = useListenStorageChange(
    JoyrideStorage.SENSOR_RIDE_ON_LOCAL_STORAGE
  );

  const { localStorageValue: metOceanReportStorageValue } =
    useListenStorageChange(
      JoyrideStorage.METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE
    );

  const { localStorageValue: initialMetOceanReportStorageValue } =
    useListenStorageChange(
      JoyrideStorage.INITIAL_METOCEAN_REPORT_RIDE_ON_LOCAL_STORAGE
    );

  const shouldRunInitialGuidedTutorial =
    !forecastLocalStorageValue || !initialSensorLocalStorageValue;

  const shouldRunGeospatialJoyride =
    forecastChartPageStore.selectedStation > 0 &&
    forecastChartPageStore.insightMode &&
    !geoSpatialLocalStorageValue &&
    forecastLocalStorageValue;

  const shouldProbabilisticJoyride =
    !!forecastChartProbabilisticStore?.probabilisticForecastData &&
    !forecastChartMeteogramStore.showMeteogram &&
    !probabilisticLocalStorageValue;

  const shouldRunSensorJoyride =
    forecastChartMeteogramStore.visualizationType ===
      VisualizationType.SENSOR && !sensorLocalStorageValue;

  const shouldRunMetOceanReportTutorial =
    !metOceanReportStorageValue &&
    !shouldRunInitialGuidedTutorial &&
    !initialMetOceanReportStorageValue;

  return (
    <Box h="92.2vh" className="joyride-forecast-first-step">
      <Box h={"88vh"}>
        <InitialGuidedTutorial
          shouldRunJoyride={shouldRunInitialGuidedTutorial}
        />

        <GeoSpatialGuidedTutorial
          shouldRunJoyride={shouldRunGeospatialJoyride}
        />

        <ProbabilisticGuidedTutorial
          shouldRunJoyride={shouldProbabilisticJoyride}
        />

        <SensorGuidedTutorial shouldRunJoyride={shouldRunSensorJoyride} />

        <MetOceanReportTutorial
          shouldRunJoyride={shouldRunMetOceanReportTutorial}
        />

        <Box position="relative">
          <I4castAlert
            visible={forecastChartPageStore.state === StoreState.SUCCESS}
            message={forecastChartPageStore.messageSuccess}
            type="success"
          />

          <I4castAlert
            visible={forecastChartPageStore.state === StoreState.ERROR}
            message={forecastChartPageStore.messageError}
            type="error"
          />

          <Box display={"grid"}>
            <ForecastChartPanelHeader />

            {forecastChartPageStore.insightMode &&
              !!forecastChartPageStore.selectedStation && (
                <ForecastChartInsightTimeline />
              )}

            <ForecastChartPanelEnvironmentalButtons
              key={`menuButtonEnvironmental${
                defaultSelectedKeys && defaultSelectedKeys[0]
              }`}
              defaultSelectedKeys={defaultSelectedKeys}
              disableMenuItems={disableMenuItems}
            />
          </Box>

          <Box>
            <Spin
              spinning={
                forecastChartPageStore.state === StoreState.PEDDING ||
                forecastChartPageStore.stateStationsWithSensorData ===
                  StoreState.PEDDING
              }
            >
              <ForecastChartPanelMap />
            </Spin>
            <ForecastChartPanelMeteogram />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(ForecastChartPage);
