import { getRoot } from "mobx-easy";
import { MacroRegion } from "../../enum/MacroRegion";
import { validatePointInsidePolygon } from "../validadePointInsidePolygon/validatePointInsidePolygon";
import { macroRegionLimits } from "./macroRegionLimits";
import RootStore from "../../stores/root-store";

export const getMacroRegionOriginFromLatLon = (
  lat: number,
  lng: number
): MacroRegion | undefined => {
  const {
    dataStores: { authStore },
  } = getRoot<RootStore>();
  for (const macroRegionOrigin in macroRegionLimits) {
    if (
      authStore.userMarcroRegions?.length &&
      !authStore.userMarcroRegions.includes(macroRegionOrigin)
    ) {
      continue;
    }
    const macroRegionLimit = macroRegionLimits[macroRegionOrigin];
    const isLocationInMacroRegion = validatePointInsidePolygon(
      [lat, lng],
      macroRegionLimit
    );
    if (isLocationInMacroRegion) {
      return macroRegionOrigin as MacroRegion;
    }
  }
  return;
};
