import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStores } from "../../../../stores/setup/use-store";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "@chakra-ui/react";
import { VisualizationType } from "../../../../enum/VisualizationType";
import { VisualizationTypeButton } from "./VisualizationTypeButton";
import { StoreState } from "enum/StoreState";

interface VisualizationTypeMenuProps {}

const VisualizationTypeMenu: React.FC<VisualizationTypeMenuProps> = ({}) => {
  const {
    dataStores: {
      forecastChartMeteogramStore,
      forecastChartPageStore,
      forecastChartProbabilisticStore,
      analyticsChartStore,
    },
  } = useStores();

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMeteogram",
  });

  const { visualizationType } = forecastChartMeteogramStore;
  const { tabStateActivty } = forecastChartPageStore;

  const handleForecastClick = () => {
    forecastChartMeteogramStore.setVisualizationType(
      VisualizationType.FORECAST
    );

    forecastChartPageStore.handleOnChangeTabMeteogram(tabStateActivty);
    forecastChartPageStore.setProbabilisticActivityVariable(null);
    forecastChartPageStore.setSensorMarkerData([]);
    forecastChartProbabilisticStore.setIsChartDataEmpty(false);
    analyticsChartStore.setIsChartDataEmpty(false);
  };

  const handleProbabilisticClick = () => {
    forecastChartMeteogramStore.setVisualizationType(
      VisualizationType.PROBABILISTIC
    );

    forecastChartPageStore.handleOnChangeTabMeteogram(tabStateActivty);
    forecastChartPageStore.setProbabilisticVariableByActivityTab();
    forecastChartPageStore.setSensorMarkerData([]);
    analyticsChartStore.setIsChartDataEmpty(false);
  };

  const handleSensorClick = () => {
    forecastChartMeteogramStore.setState(StoreState.DONE);
    forecastChartProbabilisticStore.setState(StoreState.DONE);
    forecastChartMeteogramStore.setIsChartDataEmpty(false);

    forecastChartMeteogramStore.setVisualizationType(VisualizationType.SENSOR);
    forecastChartPageStore.setActionCallGetStationsWithSensor();
  };

  useEffect(() => {
    if (
      forecastChartMeteogramStore.visualizationType !== VisualizationType.SENSOR
    )
      return;

    const fifteenMinutes = 1000 * 60 * 15;
    const intervalId = setInterval(() => {
      forecastChartPageStore.getStationsWithSensorData({
        envType: forecastChartPageStore.tabStateActivty,
      });
    }, fifteenMinutes);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forecastChartMeteogramStore.visualizationType]);

  return (
    <ButtonGroup isAttached color={"white"} size="xs" mr={"4px"}>
      <VisualizationTypeButton
        className="joyride-menu-types-forecast"
        label={t("deterministic")}
        isActive={visualizationType === VisualizationType.FORECAST}
        onClick={handleForecastClick}
      />
      <VisualizationTypeButton
        className="joyride-menu-types-probabilistic"
        label={t("probabilistic")}
        isActive={visualizationType === VisualizationType.PROBABILISTIC}
        onClick={handleProbabilisticClick}
        disabled={!!forecastChartMeteogramStore.selectForecastDateAvailable}
      />
      <VisualizationTypeButton
        className="joyride-menu-types-sensor"
        label="Sensor"
        isActive={visualizationType === VisualizationType.SENSOR}
        onClick={handleSensorClick}
        style={{ borderRight: "none" }}
      />
    </ButtonGroup>
  );
};

export default observer(VisualizationTypeMenu);
