import { message, Modal } from "antd";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DBAction } from "../../../enum/DBAction";
import { StoreState } from "../../../enum/StoreState";
import { useStores } from "../../../stores/setup/use-store";
import OperationRuleLocationInsightModal from "./OperationRuleLocationInsightModal";
// @ts-ignore
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { UserExperience } from "../../../enum/UserExperience";
import {
  Box,
  Select,
  Button,
  Flex,
  useDisclosure,
  FormLabel,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { IOperationRuleLocation } from "../../../stores/data/operationRuleLocations/OperationRuleLocationModel";
import noInsights from "../../../assets/noInsights.svg";
import EmptyState from "../../../components/EmptyState/EmptyState";
import InsightList from "../../../components/List/List";
import { IOperationRuleLocationItem } from "../../../stores/data/operationRuleLocationInsights/operationRuleLocationInsightModel";
import { AlertDelete } from "../../../components/AlertDelete/AlertDelete";
import Loading from "../../../components/Loading/Loading";
import i18n from "i18n";

const OperationRuleLocationInsightDetail: React.FC = () => {
  const {
    dataStores: {
      operationRuleLocationInsightStore,
      operationRuleLocationStore,
      oceanWeatherIntelligenceStore,
      operationRuleStore,
      authStore,
    },
  } = useStores();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.operationRuleLocationInsight",
  });

  const [visibleModalvideo, setVisibleModalVideo] = useState(false);
  const [selectedStation] = useState(
    operationRuleLocationStore.operationRuleLocationMainId
  );
  const [location, setLocation] = useState<IOperationRuleLocation>();
  const [insightRemove, setInsightRemove] =
    useState<IOperationRuleLocationItem | null>(null);

  let player: any;

  useEffect(() => {
    if (
      operationRuleStore.operationRuleMainId > 0 &&
      (selectedStation !==
        operationRuleLocationStore.operationRuleLocationMainId ||
        operationRuleLocationStore.operationRuleLocations.length === 0)
    ) {
      operationRuleLocationStore.getOperationRuleLocationsApi(
        operationRuleStore.operationRuleMainId
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationRuleStore.operationRuleMainId]);

  useEffect(() => {
    if (operationRuleLocationStore.operationRuleLocationMainId) {
      operationRuleLocationInsightStore.getOperationRuleLocationInsightApi(
        operationRuleLocationStore.operationRuleLocationMainId
      );

      const location = operationRuleLocationStore.getOperationRuleLocation(
        operationRuleLocationStore.operationRuleLocationMainId
      );
      setLocation(location);
      if (location) {
        oceanWeatherIntelligenceStore.getEnvironmentalVariableForStationAPI(
          location.stationId,
          location.macroRegionId || 0
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operationRuleLocationStore.operationRuleLocations,
    operationRuleLocationStore.actionCallInsightsList,
  ]);

  const handleSelectLocationChange = (value: number) => {
    if (value > 0) {
      operationRuleLocationStore.setOperationRuleLocationMainId(value);
      operationRuleLocationInsightStore.getOperationRuleLocationInsightApi(
        value
      );
      const locationSeletected =
        operationRuleLocationStore.getOperationRuleLocation(value);
      setLocation(locationSeletected);
      oceanWeatherIntelligenceStore.getEnvironmentalVariableForStationAPI(
        locationSeletected.stationId,
        locationSeletected.macroRegionId || 0
      );
    }
  };

  const handleOnEdit = (value: any) => {
    if (
      !operationRuleLocationStore.operationRuleLocationMainId ||
      operationRuleLocationStore.operationRuleLocationMainId <= 0
    ) {
      message.info(t("youNeedSelectLocationToEditInsight"));
    } else {
      operationRuleLocationInsightStore.setOperationRuleLocationItem(value);
      operationRuleLocationInsightStore.setOperationRuleLocationItemAction(
        DBAction.UPDATE
      );
      onOpen();
    }
  };

  const handleOnConfirmDelete = () => {
    const insightId = insightRemove?.id;
    if (insightId && insightId > 0) {
      operationRuleLocationInsightStore
        .deleteOperationRuleLocationInsight(insightId)
        .then((success) => {
          if (success) {
            if (
              !operationRuleLocationInsightStore.operationRuleLocationItens
                ?.length
            ) {
              operationRuleLocationInsightStore.setHasInsights(false);
            } else {
              operationRuleLocationInsightStore.setHasInsights(false);
            }
          }
        });
      oceanWeatherIntelligenceStore.setChangeForUpdate({
        update: true,
        expiration: 0,
      });
      setInsightRemove(null);
      onDeleteClose();
    } else {
      message.error(t("locationRemovalFailedContactUs"));
    }
  };

  const handleCreateInsight = () => {
    if (
      !operationRuleLocationStore.operationRuleLocationMainId ||
      operationRuleLocationStore.operationRuleLocationMainId <= 0
    ) {
      message.info(t("youNeedSelectLocationToAddInsight"));
    } else {
      operationRuleLocationInsightStore.setActionCallCreateInsight();
      operationRuleLocationInsightStore.setOperationRuleLocationItemAction(
        DBAction.CREATE
      );
      onOpen();
    }
  };

  const handleOnClickButtonVideo = () => {
    setVisibleModalVideo(!visibleModalvideo);
    oceanWeatherIntelligenceStore.addLogUserExperienceAPI(
      UserExperience.OWI_INSIGHT_VIDEO
    );
  };

  const playerPause = () => {
    if (player) {
      player.pause();
    }
  };

  const handleOpenDeleteModal = (insight: IOperationRuleLocationItem) => {
    setInsightRemove(insight);
    onDeleteOpen();
  };

  return (
    <Box>
      {operationRuleLocationInsightStore.state === StoreState.ERROR && (
        <Alert status="error" mb={4} color="black">
          <AlertIcon />
          {operationRuleLocationInsightStore.messageError}
        </Alert>
      )}
      {authStore.readOnlyUser && (
        <Alert bg="#bee3f8" status="info" mb={4} color="gray.700">
          <AlertIcon />
          {i18n.t("readingModePleaseContact")}
        </Alert>
      )}

      {location && (
        <OperationRuleLocationInsightModal
          location={location}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      <>
        <Modal
          title={t("diveEditDashboard")}
          visible={visibleModalvideo}
          width={"50%"}
          footer={null}
          onCancel={() => setVisibleModalVideo(!visibleModalvideo)}
          afterClose={playerPause}
          bodyStyle={{ padding: 0 }}
          closable={true}
        >
          <Player
            autoPlay
            ref={(ref: any) => {
              player = ref;
            }}
            src="https://i4sea.s3-us-west-2.amazonaws.com/public/Video+tutorial+3_Insights.mp4"
          />
        </Modal>

        {operationRuleLocationInsightStore.state === StoreState.PEDDING && (
          <Loading />
        )}

        <AlertDelete
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          titleLabel={t("deleteInsight")}
          bodyText={t("areYouSureCantUndoThis", {
            insightName: insightRemove?.name,
          })}
          cancelButtonLabel={t("cancel")}
          deleteButtonLabel={t("delete")}
          handleDelete={handleOnConfirmDelete}
        />

        <Flex>
          <Box>
            <FormLabel color={"newBlue.500"}>{t("selectLocation")}</FormLabel>
            <Select
              w={"260px"}
              borderRadius={8}
              color={"blue.100"}
              backgroundColor="#ffffff"
              size="md"
              key={`SelectLocation-${operationRuleLocationStore.operationRuleLocationMainId}`}
              onChange={(e) =>
                handleSelectLocationChange(parseInt(e.target.value))
              }
              value={operationRuleLocationStore.operationRuleLocationMainId}
              _hover={{ borderColor: "blue.100" }}
              _focus={{ borderColor: "none" }}
            >
              {operationRuleLocationStore.operationRuleLocations.map(
                (value) => (
                  <option
                    style={{ color: "black" }}
                    value={value.id}
                    key={value.id}
                  >
                    {value.name}
                  </option>
                )
              )}
            </Select>
          </Box>
          {!authStore.readOnlyUser && (
            <Button
              mt={8}
              ml={4}
              layerStyle="ocean"
              _hover={{ layerStyle: "ocean" }}
              leftIcon={<AddIcon />}
              onClick={handleCreateInsight}
            >
              {t("addNewInsight")}
            </Button>
          )}
        </Flex>

        {operationRuleLocationInsightStore.operationRuleLocationItens?.length >
          0 && (
          <InsightList
            list={operationRuleLocationInsightStore.operationRuleLocationItens}
            onEdit={handleOnEdit}
            onDelete={handleOpenDeleteModal}
            showDeleteButton={!authStore.readOnlyUser}
          />
        )}
      </>

      {!operationRuleLocationInsightStore.operationRuleLocationItens?.length &&
        !operationRuleLocationInsightStore.hasInsights && (
          <EmptyState
            description={t("weReAlmostTheNowRegisterInsight")}
            image={noInsights}
            imageSize="26vw"
            buttonAction={handleCreateInsight}
            buttonIcon={<AddIcon />}
            buttonText={
              !authStore.readOnlyUser
                ? (t("addNewInsight") as string)
                : undefined
            }
            marginTop={"6"}
          />
        )}
      <Button
        mt={2}
        mb={4}
        size="sm"
        layerStyle="ocean"
        _hover={{ layerStyle: "ocean" }}
        float="right"
        bottom={0}
        right={0}
        onClick={handleOnClickButtonVideo}
      >
        {t("videoTutorials")}
      </Button>
    </Box>
  );
};

export default observer(OperationRuleLocationInsightDetail);
