import { Modal } from "antd";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../stores/setup/use-store";
import { IOperationRule } from "../../../stores/data/operationRules/OperationRuleModel";
import { StoreState } from "../../../enum/StoreState";
// @ts-ignore
import { Player } from "video-react";

import "video-react/dist/video-react.css";
import { UserExperience } from "../../../enum/UserExperience";
import {
  Box,
  Button,
  Flex,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import emptyDashboarList from "../../../assets/emptyDashboarList.svg";
import EmptyState from "../../../components/EmptyState/EmptyState";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { Input } from "../../../components/Input/Input";
import { DashboardModal } from "./Components/DashboardModal";
import { AlertDelete } from "../../../components/AlertDelete/AlertDelete";
import { useFormik } from "formik";
import InsightList from "../../../components/List/List";
import Loading from "../../../components/Loading/Loading";
import i18n from "i18n";

const OperationRule: React.FC = () => {
  const {
    dataStores: {
      operationRuleStore,
      operationRuleLocationStore,
      operationRuleLocationInsightStore,
      oceanWeatherIntelligenceStore,
      authStore,
    },
  } = useStores();
  const [currentOperationRule, setCurrentOperationRule] = useState(0);
  const [visibleModalvideo, setVisibleModalVideo] = useState(false);
  const [dashboardRemove, setDashboardRemove] =
    useState<IOperationRule | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      initialName: "",
    },
    onSubmit: () => {},
  });

  const formikEdit = useFormik({
    initialValues: {
      editName: "",
      editInitialName: "",
    },
    onSubmit: () => {},
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const initialRef = useRef(null);
  const toast = useToast();

  const { t } = useTranslation("translation", {
    keyPrefix: "oceanWeatherIntelligence.operationRule",
  });

  let player: any;

  useEffect(() => {
    searchDashboard("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oceanWeatherIntelligenceStore.activeTab]);

  const handleAddData = (values: any) => {
    const { name, initialName } = values;

    if (name && initialName) {
      if (/^[A-Z0-9]{4}$/.test(initialName)) {
        operationRuleStore.addOperationRule(name, initialName);
        formik.resetForm();
        oceanWeatherIntelligenceStore.setChangeForUpdate({
          update: true,
          expiration: 0,
        });
        operationRuleLocationInsightStore.setOperationRuleLocationItens([]);
        operationRuleLocationStore.setOperationRuleLocations([]);
        toast({
          title: t("dashboardSuccessOnSave"),
          status: "success",
          position: "bottom-right",
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: t("youNeed4AlphaNumericToTheKey"),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: t("informNameAndKeyForAdd"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleOnEdit = (operationRule: IOperationRule) => {
    setCurrentOperationRule(operationRule.id);
    formikEdit.setFieldValue("editName", operationRule.name);
    formikEdit.setFieldValue("editInitialName", operationRule.initialName);
    setIsEditing(true);
    onOpen();
  };

  const handleSaveEditData = () => {
    const { editName, editInitialName } = formikEdit.values;
    if (editName && editInitialName) {
      if (/^[A-Z0-9]{4}$/.test(editInitialName)) {
        if (currentOperationRule > 0) {
          operationRuleStore
            .updateOperationRuleDashboard(
              currentOperationRule,
              editName,
              editInitialName
            )
            .then(() => {
              if (operationRuleStore.state !== StoreState.ERROR) {
                toast({
                  title: t("dashboardSuccessOnSave"),
                  status: "success",
                  position: "bottom-right",
                  isClosable: true,
                });
              }
            });
          setCurrentOperationRule(0);
          if (operationRuleStore.operationRuleMainId === currentOperationRule) {
            operationRuleStore.setOperationRuleMain(0);
            operationRuleLocationStore.reset();
            operationRuleLocationInsightStore.reset();
          }
        }

        onClose();
      } else {
        toast({
          title: t("youNeed4AlphaNumericToTheKey"),
          status: "error",
          position: "top",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: t("informNameAndKeyForEdit"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleOnConfirmDelete = () => {
    const dashboardId = dashboardRemove?.id;

    if (dashboardId && dashboardId > 0) {
      operationRuleStore
        .deleteOperationRuleDashboard(dashboardId)
        .then((success) => {
          if (success) {
            if (!operationRuleLocationStore.operationRuleLocations?.length) {
              operationRuleStore.setHasDashboards(false);
              operationRuleLocationStore.setHasLocations(false);
              operationRuleLocationInsightStore.setHasInsights(false);
            } else {
              operationRuleLocationStore.setHasLocations(true);
            }
          }
        });
      if (operationRuleStore.operationRuleMainId === dashboardId) {
        operationRuleLocationStore.reset();
        operationRuleLocationInsightStore.reset();
        oceanWeatherIntelligenceStore.reset();
      }
      setDashboardRemove(null);
      onDeleteClose();
    } else {
      toast({
        title: t("removalFailedContactUs"),
        status: "error",
        position: "top",
        isClosable: true,
      });
    }
  };

  const playerPause = () => {
    if (player) {
      player.pause();
    }
  };

  const handleOnClickButtonVideo = () => {
    setVisibleModalVideo(!visibleModalvideo);
    oceanWeatherIntelligenceStore.addLogUserExperienceAPI(
      UserExperience.OWI_DASHBOARD_VIDEO
    );
  };

  const searchDashboard = useCallback(
    (value: string) => {
      operationRuleStore.setSearchValue(value);

      operationRuleStore.setFilteredDashboards(
        operationRuleStore.getFilteredDashboard()
      );
    },
    [operationRuleStore]
  );

  const handleOpenDeleteModal = (dashboard: IOperationRule) => {
    setDashboardRemove(dashboard);
    onDeleteOpen();
  };

  const handleOpenAddModal = () => {
    setIsEditing(false);
    onOpen();
    formik.resetForm();
  };

  return (
    <Box>
      {operationRuleStore.state === StoreState.ERROR && (
        <Alert status="error" mb={4} color="black">
          <AlertIcon />
          {operationRuleStore.messageError}
        </Alert>
      )}
      {authStore.readOnlyUser && (
        <Alert bg="#bee3f8" status="info" mb={4} color="gray.700">
          <AlertIcon />
          {i18n.t("readingModePleaseContact")}
        </Alert>
      )}

      <Modal
        title={t("diveIntoEditDashboard")}
        visible={visibleModalvideo}
        width={"50%"}
        footer={null}
        onCancel={() => setVisibleModalVideo(!visibleModalvideo)}
        afterClose={playerPause}
        bodyStyle={{ padding: 0 }}
        closable={true}
      >
        <Player
          autoPlay
          ref={(ref: any) => {
            player = ref;
          }}
          src="https://i4sea.s3-us-west-2.amazonaws.com/public/video+tutorial+2_Dashboards+e+locations.mp4"
        />
      </Modal>

      <AlertDelete
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        titleLabel={t("deleteDashboard")}
        bodyText={t("areYouSureCantUndoThis", {
          dashboardName: dashboardRemove?.name,
        })}
        cancelButtonLabel={t("cancel")}
        deleteButtonLabel={t("delete")}
        handleDelete={handleOnConfirmDelete}
      />

      <Flex>
        <Input
          width="300px"
          placeholder={t("search") as string}
          icon={<SearchIcon />}
          value={operationRuleStore.searchValue}
          onChange={(e) => searchDashboard(e.target.value)}
        />
        {!authStore.readOnlyUser && (
          <Button
            ml={4}
            leftIcon={<AddIcon />}
            layerStyle="ocean"
            _hover={{ layerStyle: "ocean" }}
            onClick={handleOpenAddModal}
          >
            {t("newDashboard")}
          </Button>
        )}
      </Flex>

      <DashboardModal
        initialValues={
          isEditing ? formikEdit.initialValues : formik.initialValues
        }
        formik={isEditing ? formikEdit : formik}
        isOpen={isOpen}
        onClose={onClose}
        initialRef={initialRef}
        onConfirm={isEditing ? handleSaveEditData : handleAddData}
        title={isEditing ? t("editDashboard") : t("createDashboard")}
        cancelButtonText={t("cancel") as string}
        confirmButtonText={
          isEditing ? (t("save") as string) : (t("add") as string)
        }
        nameFieldName={isEditing ? "editName" : "name"}
        keyFieldName={isEditing ? "editInitialName" : "initialName"}
        nameFieldLabel={t("name") as string}
        keyFieldLabel={t("key") as string}
        nameFieldPlaceholder={t("informDashboardName") as string}
        keyFieldPlaceholder={"ex: ABC2 ABCD A2BC"}
        keyFieldMaxLength={4}
        nameFieldMaxLength={60}
      />
      {operationRuleStore.state === StoreState.PEDDING && <Loading />}

      <InsightList
        list={operationRuleStore.filteredDashboards}
        onEdit={handleOnEdit}
        onDelete={handleOpenDeleteModal}
        showEditButton={!authStore.readOnlyUser}
        showDeleteButton={!authStore.readOnlyUser}
      />

      {!operationRuleStore.operationRules?.length &&
        !operationRuleStore.hasDashboards && (
          <EmptyState
            key={`id-${operationRuleLocationStore.hasLocations}`}
            description={t("startCreatingFirstInsight")}
            buttonAction={handleOpenAddModal}
            buttonIcon={<AddIcon />}
            buttonText={
              !authStore.readOnlyUser
                ? (t("newDashboard") as string)
                : undefined
            }
            image={emptyDashboarList}
            imageSize="20vw"
          />
        )}

      <Button
        mt={2}
        mb={4}
        size="sm"
        layerStyle="ocean"
        _hover={{ layerStyle: "ocean" }}
        float="right"
        bottom={0}
        right={0}
        onClick={handleOnClickButtonVideo}
      >
        {t("videoTutorials")}
      </Button>
    </Box>
  );
};
export default observer(OperationRule);
