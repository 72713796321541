import Highcharts from "highcharts";
import { transformGeo } from "../transformGeo/transformGeo";

export const meteogramFormatTooltip = (
  x: number,
  y: any,
  points: any,
  insightName: string | undefined = undefined
) => {
  let ret =
    '<b style="font-size: 15px;">' +
    Highcharts.dateFormat("%d/%m/%Y %H:%M", x) +
    "</b>";

  ret += "<table>";

  if (points) {
    // Add all series
    for (const point of points) {
      const serie = point.series;
      if (insightName && serie.userOptions?.name === insightName) {
        ret +=
          '<tr><td><span style="font-size: 15px; color:' +
          serie.color +
          '">\u25CF</span> ' +
          serie.name +
          "</td></tr>";
      } else {
        const value = point?.y;
        const high = point?.point?.high;
        const low = point?.point?.low;
        if (value === undefined) continue;
        if (serie.options.tooltip.formatDirection) {
          let direction = point.point.direction;
          if (serie.options.tooltip.reverseDirection && direction !== null) {
            direction = reverseDirection(direction);
          }
          direction = direction === null ? "" : transformGeo(direction);
          ret +=
            '<tr><td><span style="font-size: 15px; color:' +
            serie.color +
            '">\u25CF</span> ' +
            serie.name +
            ": &nbsp;</td>" +
            '<td style="font-size: 15px; white-space:nowrap">' +
            point?.y.toFixed(2) +
            " " +
            serie.options.tooltip.valueSuffix +
            " (" +
            direction +
            ")" +
            "</td></tr>";
        } else if (high !== undefined && low !== undefined) {
          ret +=
            '<tr><td><span style="font-size: 15px; color:' +
            serie.color +
            '">\u25CF</span> ' +
            serie.name +
            ': &nbsp;</td><td style="font-size: 15px; white-space:nowrap">' +
            low.toFixed(2) +
            " - " +
            high.toFixed(2) +
            " " +
            serie.options.tooltip.valueSuffix +
            "</td></tr>";
        } else {
          ret +=
            '<tr><td><span style="font-size: 15px; color:' +
            serie.color +
            '">\u25CF</span> ' +
            serie.name +
            ': &nbsp;</td><td style="font-size: 15px; white-space:nowrap">' +
            point.y.toFixed(2) +
            " " +
            serie.options.tooltip.valueSuffix +
            "</td></tr>";
        }
      }
    }
  } else {
    ret +=
      '<tr><td><span style="font-size: 15px; color:' +
      '">\u25CF</span> ' +
      "probabilidade" +
      ': &nbsp;</td><td style="font-size: 15px; white-space:nowrap">' +
      y.toFixed(0) +
      "%" +
      "</td></tr>";
  }
  // Close
  ret += "</table>";

  return ret;
};

export const reverseDirection = (value: number) => {
  if (value > 180) {
    return (value - 180) % 360;
  } else {
    return (value + 180) % 360;
  }
};
