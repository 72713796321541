import { EnvironmentalType } from "../../enum/EnvironmentalType";
import { MacroRegion } from "../../enum/MacroRegion";

export const getGridLimitsFromConfig = (
  environmentalType: string,
  macroRegionOrigin: MacroRegion
): number[][][] | undefined => {
  if (macroRegionOrigin === MacroRegion.PORT_OF_ACU) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.acu;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.acu;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.acu;
    }
  } else if (macroRegionOrigin === MacroRegion.ALL_SAINTS_BAY) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.bts;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.bts;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.bts;
    }
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_SANTOS) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.san;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.san;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.san;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_ITAGUAI) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.itg;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.itg;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.itg;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PATOS_LAGOON) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.ldp;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.ldp;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.ldp;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_PARANAGUA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.prg;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.prg;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_ITAJAI) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.itj;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.itj;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return;
    }
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_ROTTERDAM) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.rot;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_PECEM) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.pcm;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.pcm;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.pcm;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PORT_OF_SINES) {
    if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.sns;
    } else if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.sns;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.GERMAN_NORTH_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.mdn;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.mdn;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.mdn;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.DANISH_NORTH_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.den;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.den;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.den;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.SCOTLAND_NORTH_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.esc;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.esc;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.esc;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.SOUTHERN_BIGHT_NORTH_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.sbg;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.sbg;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.sbg;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.IRISH_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.irl;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.irl;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.irl;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.BALTIC_SEA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.bal;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.bal;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.bal;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_ESP_SANTO) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.eps;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.eps;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.eps;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_ANTOFAGASTA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.afg;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.afg;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_VALPARAISO) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.val;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.val;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.BARRA_NORTE) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.brn;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.brn;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.SAO_FRANCISCO_DO_SUL) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.sfs;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.sfs;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.sfs;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.SOUTH_BAHIA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.sba;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.sba;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.sba;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_ALAGOAS) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.mac;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_RIO_GRANDE_DO_NORTE) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.ntl;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_PIAUI) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.pia;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_MARANHAO) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.bsm;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.bsm;
    } else if (environmentalType === EnvironmentalType.WATER_FLOW) {
      return config.water_flow.bsm;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_PARA) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.par;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_RIO_DE_JANEIRO) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.rja;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.COAST_OF_BELGIUM) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.bel;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.MATO_GROSSO_DO_SUL) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.mgs;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.MATO_GROSSO) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.mtg;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PERU_NORTE) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.pen;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.pen;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PERU_CENTRAL) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.pec;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.pec;
    }
    return;
  } else if (macroRegionOrigin === MacroRegion.PERU_SUL) {
    if (
      environmentalType === EnvironmentalType.WEATHER ||
      environmentalType === EnvironmentalType.WEATHER_TEMPERATURE
    ) {
      return config.weather.pes;
    } else if (environmentalType === EnvironmentalType.WAVE) {
      return config.wave.pes;
    }
    return;
  }
};

export const regionToMacroRegion = (
  regionId: number
): MacroRegion | undefined => {
  const acu = [8, 7];
  const bts = [1, 3, 5, 15];
  const san = [25];
  const ldp = [26];
  const renoRiver = [18, 24];
  const itg = [14];
  const itj = [19];
  const prg = [27];
  const rot = [28];
  const pcm = [9];
  const sns = [12];
  const eps = [30];
  const afg = [31];
  const brn = [32];
  const sfs = [33];
  const sba = [34];
  const mac = [35];
  const ntl = [36];
  const pia = [37];
  const bsm = [10];
  const par = [38];
  const rja = [39];
  const bel = [40];
  const mgs = [41];
  const pen = [42];
  const pec = [43];
  const pes = [44];
  const mtg = [45];
  const val = [46];
  const mdn = [47];
  const den = [49];
  const esc = [50];
  const sbg = [51];
  const irl = [52];
  const bal = [53];

  if (acu.includes(regionId)) {
    return MacroRegion.PORT_OF_ACU;
  } else if (bts.includes(regionId)) {
    return MacroRegion.ALL_SAINTS_BAY;
  } else if (san.includes(regionId)) {
    return MacroRegion.PORT_OF_SANTOS;
  } else if (renoRiver.includes(regionId)) {
    return MacroRegion.RIVER_RHINE_GERMANY;
  } else if (itg.includes(regionId)) {
    return MacroRegion.PORT_OF_ITAGUAI;
  } else if (itj.includes(regionId)) {
    return MacroRegion.PORT_OF_ITAJAI;
  } else if (ldp.includes(regionId)) {
    return MacroRegion.PATOS_LAGOON;
  } else if (prg.includes(regionId)) {
    return MacroRegion.PORT_OF_PARANAGUA;
  } else if (rot.includes(regionId)) {
    return MacroRegion.PORT_OF_ROTTERDAM;
  } else if (pcm.includes(regionId)) {
    return MacroRegion.PORT_OF_PECEM;
  } else if (sns.includes(regionId)) {
    return MacroRegion.PORT_OF_SINES;
  } else if (mdn.includes(regionId)) {
    return MacroRegion.GERMAN_NORTH_SEA;
  } else if (den.includes(regionId)) {
    return MacroRegion.DANISH_NORTH_SEA;
  } else if (esc.includes(regionId)) {
    return MacroRegion.SCOTLAND_NORTH_SEA;
  } else if (sbg.includes(regionId)) {
    return MacroRegion.SOUTHERN_BIGHT_NORTH_SEA;
  } else if (irl.includes(regionId)) {
    return MacroRegion.IRISH_SEA;
  } else if (bal.includes(regionId)) {
    return MacroRegion.BALTIC_SEA;
  } else if (eps.includes(regionId)) {
    return MacroRegion.COAST_OF_ESP_SANTO;
  } else if (afg.includes(regionId)) {
    return MacroRegion.COAST_OF_ANTOFAGASTA;
  } else if (val.includes(regionId)) {
    return MacroRegion.COAST_OF_VALPARAISO;
  } else if (brn.includes(regionId)) {
    return MacroRegion.BARRA_NORTE;
  } else if (sfs.includes(regionId)) {
    return MacroRegion.SAO_FRANCISCO_DO_SUL;
  } else if (sba.includes(regionId)) {
    return MacroRegion.SOUTH_BAHIA;
  } else if (mac.includes(regionId)) {
    return MacroRegion.COAST_OF_ALAGOAS;
  } else if (ntl.includes(regionId)) {
    return MacroRegion.COAST_OF_RIO_GRANDE_DO_NORTE;
  } else if (pia.includes(regionId)) {
    return MacroRegion.COAST_OF_PIAUI;
  } else if (bsm.includes(regionId)) {
    return MacroRegion.COAST_OF_MARANHAO;
  } else if (par.includes(regionId)) {
    return MacroRegion.COAST_OF_PARA;
  } else if (rja.includes(regionId)) {
    return MacroRegion.COAST_OF_RIO_DE_JANEIRO;
  } else if (bel.includes(regionId)) {
    return MacroRegion.COAST_OF_BELGIUM;
  } else if (mgs.includes(regionId)) {
    return MacroRegion.MATO_GROSSO_DO_SUL;
  } else if (pen.includes(regionId)) {
    return MacroRegion.PERU_NORTE;
  } else if (pec.includes(regionId)) {
    return MacroRegion.PERU_CENTRAL;
  } else if (pes.includes(regionId)) {
    return MacroRegion.PERU_SUL;
  } else if (mtg.includes(regionId)) {
    return MacroRegion.MATO_GROSSO;
  }
};

const config = {
  weather: {
    acu: [
      [
        [-21.0, -42.0],
        [-23.38, -42.0],
        [-23.38, -40.0],
        [-21.0, -40.0],
        [-21.0, -42.0],
      ],
    ],
    bts: [
      [
        [-14.915, -41.18],
        [-14.915, -36.545],
        [-8.8, -36.545],
        [-8.8, -41.18],
        [-14.915, -41.18],
      ],
    ],
    san: [
      [
        [-24.91, -49.06],
        [-24.91, -45.38],
        [-23.07, -45.38],
        [-23.07, -49.06],
        [-24.91, -49.06],
      ],
    ],
    itg: [
      [
        [-24.901033629484573, -45.371038114512714],
        [-24.901033629484573, -43.511061639298106],
        [-22.27253079949385, -43.511061639298106],
        [-22.27253079949385, -45.371038114512714],
        [-24.901033629484573, -45.371038114512714],
      ],
    ],
    itj: [
      [
        [-26.46622540562123, -46.52104471070345],
        [-26.46622540562123, -50.232416871535804],
        [-29.318565778986645, -50.232416871535804],
        [-29.318565778986645, -46.52104471070345],
        [-26.46622540562123, -46.52104471070345],
      ],
    ],
    ldp: [
      [
        [-33.360353847904605, -48.948318963123086],
        [-29.516767215882838, -48.972635859140894],
        [-29.516765810207866, -53.35790512688045],
        [-29.511902048519286, -53.918383170153774],
        [-33.414889278650804, -53.92098318700967],
        [-33.360353847904605, -48.948318963123086],
      ],
    ],
    prg: [
      [
        [-25.992850919303137, -49.44783132882179],
        [-25.992850919303137, -47.53577657121269],
        [-24.97663123173696, -47.53577657121269],
        [-24.97663123173696, -49.44783132882179],
        [-25.992850919303137, -49.44783132882179],
      ],
    ],
    rot: [
      [
        [51.65, 2.6],
        [51.65, 5.9],
        [53, 5.9],
        [53, 2.6],
        [51.65, 2.6],
      ],
    ],
    pcm: [
      [
        [-4.0, -39.57],
        [-2.9, -39.57],
        [-2.9, -38.23],
        [-4.0, -38.23],
        [-4.0, -39.57],
      ],
    ],
    sns: [
      [
        [38.1129497, -8.7533569],
        [38.1129497, -9.7805786],
        [37.6381599, -9.7943115],
        [37.6359849, -8.7561035],
        [38.1129497, -8.7533569],
      ],
    ],
    mdn: [
      [
        [55.85, 6],
        [54.5, 6],
        [54.5, 9],
        [55.85, 9],
        [55.85, 6],
      ],
    ],
    den: [
      [
        [55.851, 6],
        [55.851, 9],
        [57.1, 9],
        [57.1, 6],
        [55.851, 6],
      ],
    ],
    esc: [
      [
        [57.5, -2.75],
        [56.3, -2.75],
        [56.3, -0.5],
        [57.5, -0.5],
        [57.5, -2.75],
      ],
    ],
    sbg: [
      [
        [53, 5],
        [53, 0.25],
        [50.5, 0.25],
        [50.5, 5],
        [53, 5],
      ],
    ],
    irl: [
      [
        [52.4, -2.7],
        [55.5, -2.7],
        [55.5, -7.2],
        [52.4, -7.2],
        [52.4, -2.7],
      ],
    ],
    bal: [
      [
        [56.3, 10.8],
        [54.1, 10.8],
        [54.1, 15.9],
        [56.3, 15.9],
        [56.3, 10.8],
      ],
    ],
    eps: [
      [
        [-20.75, -41.0],
        [-20.75, -39.15],
        [-19.19, -39.15],
        [-19.19, -41.0],
        [-20.75, -41.0],
      ],
    ],
    afg: [
      [
        [-18.025, -72.15],
        [-26.99, -72.15],
        [-26.99, -69.2],
        [-18.025, -69.2],
        [-18.025, -72.15],
      ],
    ],
    val: [
      [
        [-37.27, -73.7],
        [-37.27, -70.4],
        [-27, -70.4],
        [-27, -73.7],
        [-37.27, -73.7],
      ],
    ],
    brn: [
      [
        [2.4524215891347865, -50.79973997453524],
        [-0.6353338040624834, -54.378630521032136],
        [-1.3432829495417309, -60.88441841016892],
        [-4.698519715844242, -60.970789154659684],
        [-4.8224820767031105, -55.140172169139845],
        [-2.0373802513448, -52.90155098595197],
        [-1.0801975275516043, -50.27346546332683],
        [-0.2150521539113015, -48.39762460023695],
        [0.6675479333829912, -47.904558145378786],
        [1.7777206016641571, -47.784469951824605],
        [2.2369355826132136, -48.94947394372704],
        [2.4524215891347865, -50.79973997453524],
      ],
    ],
    sfs: [
      [
        [-26.0, -47.54],
        [-26.0, -50.23],
        [-26.45, -50.23],
        [-26.45, -47.54],
        [-26.0, -47.54],
      ],
    ],
    sba: [
      [
        [-15.0, -40.2],
        [-17.1, -40.2],
        [-17.1, -37.4],
        [-15.0, -37.4],
        [-15.0, -40.2],
      ],
    ],
    mac: [
      [
        [-8.79, -36.43],
        [-11.6, -36.43],
        [-11.6, -34.46],
        [-8.79, -34.46],
        [-8.79, -36.43],
      ],
    ],
    ntl: [
      [
        [-3.682, -37.8],
        [-6.74, -37.8],
        [-6.74, -34.25],
        [-3.682, -34.25],
        [-3.682, -37.8],
      ],
    ],
    pia: [
      [
        [-5.238, -40.97],
        [-2.046, -40.97],
        [-2.046, -42.88],
        [-5.238, -42.88],
        [-5.238, -40.97],
      ],
    ],
    bsm: [
      [
        [-5.06, -43.0],
        [-0.65, -43.0],
        [-0.65, -46.14],
        [-5.06, -46.14],
        [-5.06, -43.0],
      ],
    ],
    par: [
      [
        [-4.6612441727450005, -51.50909016234036],
        [-4.662835092384967, -47.57014042705896],
        [-2.791035667615077, -46.35241953829032],
        [0.9995461257156251, -46.23140705481052],
        [1.695882015358734, -47.7279101910504],
        [0.49884088384520453, -47.85457641519045],
        [-0.2707464332701335, -48.35224519488128],
        [-1.7237833569109, -51.533090098539674],
        [-4.6612441727450005, -51.50909016234036],
      ],
    ],
    rja: [
      [
        [-24.308435464336, -43.50939808028431],
        [-24.308435464336, -42.0135174104322],
        [-22.238819947362515, -42.0135174104322],
        [-22.238819947362515, -43.50939808028431],
        [-24.308435464336, -43.50939808028431],
      ],
    ],
    bel: [
      [
        [51.59492822186348, 2.5045136423212],
        [50.77126263643268, 2.5045136423212],
        [50.77126263643268, 4.870696302291805],
        [51.59492822186348, 4.870696302291805],
        [51.59492822186348, 2.5045136423212],
      ],
    ],
    mgs: [
      [
        [-17.89, -56.12],
        [-22.18, -56.12],
        [-22.18, -51.49],
        [-17.89, -51.49],
        [-17.89, -56.12],
      ],
    ],
    pen: [
      [
        [-3.3, -82.4],
        [-9.9, -82.4],
        [-9.9, -77.5],
        [-3.3, -77.5],
        [-3.3, -82.4],
      ],
    ],
    pec: [
      [
        [-9.901, -80],
        [-14.65, -80],
        [-14.65, -75],
        [-9.901, -75],
        [-9.901, -80],
      ],
    ],
    pes: [
      [
        [-14.655, -76],
        [-18.02, -76],
        [-18.02, -70],
        [-14.655, -70],
        [-14.655, -76],
      ],
    ],
    mtg: [
      [
        [-11.8, -52.8],
        [-15, -52.8],
        [-15, -50],
        [-11.8, -50],
        [-11.8, -52.8],
      ],
    ],
  },
  wave: {
    // grid1
    acu: [
      [
        [-22.000939, -41.109497],
        [-22.000939, -40.047134],
        [-21.042717, -40.047134],
        [-21.042717, -41.109497],
        [-22.000939, -41.109497],
      ],
    ],
    // grid1
    bts: [
      [
        [-14.157364, -39.137367],
        [-14.157364, -36.470703],
        [-11.706916, -36.470703],
        [-11.706916, -39.137367],
        [-14.157364, -39.137367],
      ],
    ],
    // grid1
    san: [
      [
        [-23.2490502340233, -45.408164803357124],
        [-23.2490502340233, -48.25532211561966],
        [-24.878845570140783, -48.25532211561966],
        [-24.878845570140783, -45.408164803357124],
        [-23.2490502340233, -45.408164803357124],
      ],
    ],
    // grid1
    itg: [
      [
        [-23.4506483, -44.7363281],
        [-23.3044236, -43.5168466],
        [-22.8546637, -43.5992431],
        [-22.9331007, -44.7555541],
        [-23.4506483, -44.7363281],
      ],
    ],
    itj: [
      [
        [-26.684889, -48.6907196],
        [-27.3815231, -48.69140625],
        [-27.366889, -47.5378418],
        [-26.6744588, -47.5378418],
        [-26.684889, -48.6907196],
      ],
    ],
    // grid1
    prg: [
      [
        [-25.968, -48.8581],
        [-24.993, -48.8581],
        [-24.993, -47.595],
        [-25.968, -47.595],
        [-25.968, -48.8581],
      ],
    ],
    // grid1
    pcm: [
      [
        [-3.486006, -39.023437],
        [-3.720374, -38.7117],
        [-3.450366, -38.45077],
        [-3.263919, -38.77487],
        [-3.486006, -39.023437],
      ],
    ],
    // grid1
    sns: [
      [
        [38.1129497, -8.7533569],
        [38.1129497, -9.7805786],
        [37.6381599, -9.7943115],
        [37.6359849, -8.7561035],
        [38.1129497, -8.7533569],
      ],
    ],
    // grid1
    mdn: [
      [
        [55.85, 6],
        [54.5, 6],
        [54.5, 9],
        [55.85, 9],
        [55.85, 6],
      ],
    ],
    // grid1
    den: [
      [
        [55.851, 6],
        [55.851, 9],
        [57.1, 9],
        [57.1, 6],
        [55.851, 6],
      ],
    ],
    // grid1
    esc: [
      [
        [57.5, -2.75],
        [56.3, -2.75],
        [56.3, -0.5],
        [57.5, -0.5],
        [57.5, -2.75],
      ],
    ],
    // grid1
    sbg: [
      [
        [53, 5],
        [53, 0.25],
        [50.5, 0.25],
        [50.5, 5],
        [53, 5],
      ],
    ],
    // grid1
    irl: [
      [
        [52.4, -2.7],
        [55.5, -2.7],
        [55.5, -7.2],
        [52.4, -7.2],
        [52.4, -2.7],
      ],
    ],
    // grid1
    bal: [
      [
        [56.3, 10.8],
        [54.1, 10.8],
        [54.1, 15.9],
        [56.3, 15.9],
        [56.3, 10.8],
      ],
    ],
    // grid1
    eps: [
      [
        [-20.75, -41.0],
        [-20.75, -39.15],
        [-19.19, -39.15],
        [-19.19, -41.0],
        [-20.75, -41.0],
      ],
    ],
    // grid1
    afg: [
      [
        [-18.15, -71.915],
        [-24.93, -71.915],
        [-24.93, -69.92],
        [-18.15, -69.92],
        [-18.15, -71.915],
      ],
    ],
    val: [
      [
        [-31.62, -73.7],
        [-37.27, -73.7],
        [-37.27, -71.238],
        [-31.62, -71.238],
        [-31.62, -73.7],
      ],
    ],
    sfs: [
      [
        [-26.014356770245257, -49.05976409328511],
        [-26.432440436449966, -49.05976409328511],
        [-26.432440436449966, -47.556219801236495],
        [-26.014356770245257, -47.556219801236495],
        [-26.014356770245257, -49.05976409328511],
      ],
    ],
    sba: [
      [
        [-16.725, -37.96],
        [-15.35, -37.96],
        [-15.35, -39.16],
        [-16.725, -39.16],
        [-16.725, -37.96],
      ],
    ],
    ldp: [
      [
        [-29.334958415905966, -52.16524624129343],
        [-31.94798117963053, -54.6224502357163],
        [-33.62301777624342, -50.65456638855008],
        [-30.0385175853951, -48.644121344837714],
        [-29.334958415905966, -52.16524624129343],
      ],
    ],
    pen: [
      [
        [-4.08, -82.3],
        [-8.91, -82.3],
        [-8.91, -78.575],
        [-4.08, -78.575],
        [-4.08, -82.3],
      ],
    ],
    pec: [
      [
        [-11.23, -78.315],
        [-14.12, -78.315],
        [-14.12, -76.148],
        [-11.23, -76.148],
        [-11.23, -78.315],
      ],
    ],
    pes: [
      [
        [-16.171, -73.56],
        [-17.984, -73.56],
        [-17.984, -70.823],
        [-16.171, -70.823],
        [-16.171, -73.56],
      ],
    ],
    bsm: [
      [
        [-0.645, -44.95],
        [-2.8, -44.95],
        [-2.8, -42.9],
        [-0.645, -42.9],
        [-0.645, -44.95],
      ],
    ],
  },
  water_flow: {
    acu: [
      [
        [-21.947503777580298, -40.99754333496094],
        [-21.918204186806975, -40.76957702636719],
        [-21.670191096200437, -40.81901550292969],
        [-21.712938574040148, -41.06620788574219],
        [-21.947503777580298, -40.99754333496094],
      ],
    ],
    bts: [
      [
        [-13.168994, -38.98256],
        [-13.168994, -38.40926],
        [-12.587295, -38.40926],
        [-12.587295, -38.98256],
        [-13.168994, -38.98256],
      ],
    ],
    san: [
      [
        [-24.0831445, -46.39316939],
        [-23.8661445, -46.39316939],
        [-23.8661445, -46.28366939],
        [-24.0831445, -46.28366939],
        [-24.0831445, -46.39316939],
      ],
    ],
    itg: [
      [
        [-23.04845964337734, -43.97106170654297],
        [-23.07309889001266, -43.90514373779297],
        [-23.06362279054334, -43.79974365234375],
        [-23.054461926693815, -43.697776794433594],
        [-23.027291562110403, -43.58551025390625],
        [-22.87617519305325, -43.845062255859375],
        [-22.944641068871377, -44.021358489990234],
        [-23.04845964337734, -43.97106170654297],
      ],
    ],
    pcm: [
      [
        [-3.51856, -38.89881],
        [-3.60525, -38.77555],
        [-3.5117, -38.69796],
        [-3.43974, -38.81641],
        [-3.51856, -38.89881],
      ],
    ],
    eps: [
      [
        [-20.26566912082832, -40.34135588745792],
        [-20.357280107930194, -40.4015457059032],
        [-20.385030298392294, -40.304847309056726],
        [-20.373925359270814, -40.227730052231436],
        [-20.324377526180882, -40.18022221636528],
        [-20.153025953822677, -40.040592946389694],
        [-19.981353626152938, -39.96538334606939],
        [-19.859187472964635, -39.92996694821744],
        [-19.758158356040994, -40.01386816866574],
        [-19.867501168939697, -40.141363054443616],
        [-20.26566912082832, -40.34135588745792],
      ],
    ],
    brn: [
      [
        [-0.1410679672257089, -51.270461856999674],
        [-0.33688582978530235, -50.66829774826084],
        [-0.2762842908643819, -50.650667698274844],
        [-0.14240438940154831, -50.47050093673255],
        [-0.10408206319935687, -50.401819252549444],
        [-0.10607285279633061, -50.30675888941508],
        [-0.28780753722593033, -50.04937491732508],
        [-0.2257310614823922, -49.13939525813461],
        [-0.03533077420549091, -48.33227207187997],
        [0.6724445590317032, -48.22465476131049],
        [1.2435619218620957, -48.41091549114256],
        [1.5539035915502382, -48.692376149556196],
        [1.8104216877667767, -49.43741962968738],
        [1.7525019154621475, -49.95894967321874],
        [1.1799665376695572, -50.019193806170506],
        [0.11205058539459856, -51.027639162952084],
        [-0.04639862438203579, -51.087261643920414],
        [-0.054860813411735876, -51.106734274378766],
        [-0.05094126512960884, -51.142532830619785],
        [-0.06051830932915436, -51.16032574844016],
        [-0.04974413449998849, -51.19690207484851],
        [-0.06662591843431187, -51.21031310560724],
        [-0.1410679672257089, -51.270461856999674],
      ],
    ],
    sfs: [
      [
        [-26.085, -48.797],
        [-26.368, -48.797],
        [-26.368, -48.378],
        [-26.085, -48.378],
        [-26.085, -48.797],
      ],
    ],
    sba: [
      [
        [-15.94, -38.963],
        [-16.15, -38.963],
        [-16.15, -38.775],
        [-15.94, -38.775],
        [-15.94, -38.963],
      ],
    ],
    bsm: [
      [
        [-3.103995373163116, -44.58766108167367],
        [-3.151146081687557, -44.52106814343588],
        [-2.969628615402442, -44.40099009075439],
        [-2.6374308897755157, -44.302372957280824],
        [-2.4063661982561513, -44.043571778143445],
        [-2.140583710189034, -43.90739169849701],
        [-1.939805509695148, -44.33499153991008],
        [-2.1547154656571905, -44.44569952414082],
        [-2.236995385534101, -44.519217863217506],
        [-3.0191587143654317, -44.73202048450622],
        [-3.103995373163116, -44.58766108167367],
      ],
    ],
    ldp: [
      [
        [-32.32431136986501, -52.07020411435877],
        [-32.141597483057204, -51.88797154657212],
        [-32.06960033772188, -52.00713611914033],
        [-32.048332997120696, -52.01921713234603],
        [-31.459120657701618, -51.18916158331933],
        [-30.23976202125902, -50.31347934498223],
        [-30.02164127502685, -51.2122057201351],
        [-30.034101367823183, -51.351120495662116],
        [-30.413069993170453, -51.33599936792072],
        [-31.258661188195127, -51.91479691523287],
        [-31.72642291224878, -52.253868677871594],
        [-32.08210810817383, -52.27978500559095],
        [-32.16494245712677, -52.12173578832855],
        [-32.21700817012807, -52.18883332444642],
        [-32.32431136986501, -52.07020411435877],
      ],
    ],
    mdn: [
      [
        [55.85, 6],
        [54.5, 6],
        [54.5, 9],
        [55.85, 9],
        [55.85, 6],
      ],
    ],
    den: [
      [
        [55.851, 6],
        [55.851, 9],
        [57.1, 9],
        [57.1, 6],
        [55.851, 6],
      ],
    ],
    esc: [
      [
        [57.5, -2.75],
        [56.3, -2.75],
        [56.3, -0.5],
        [57.5, -0.5],
        [57.5, -2.75],
      ],
    ],
    sbg: [
      [
        [53, 5],
        [53, 0.25],
        [50.5, 0.25],
        [50.5, 5],
        [53, 5],
      ],
    ],
    irl: [
      [
        [52.4, -2.7],
        [55.5, -2.7],
        [55.5, -7.2],
        [52.4, -7.2],
        [52.4, -2.7],
      ],
    ],
    bal: [
      [
        [56.3, 10.8],
        [54.1, 10.8],
        [54.1, 15.9],
        [56.3, 15.9],
        [56.3, 10.8],
      ],
    ],
  },
};
