import { CancelToken } from "axios";
import api from "./api";

export interface ProbabilisticForecastParams {
  latitude: number;
  longitude: number;
  environmentalVariable: string;
  macroregionOrigin: string;
  dataInterval?: number;
  timestampStart?: number;
  timestampEnd?: number;
}

export interface IProbabilisticForecastChart {
  deterministic: number[][];
  confidence50: number[][];
  confidence80: number[][];
  median?: number[][];
  timezoneOffset: number;
  environmentalVariable: string;
  units: string;
  units_symbol: string;
  min: number;
  max: number;
  cancelToken: CancelToken;
}

export default class ProbabilisticForecastService {
  async getProbabilisticForecastData(
    {
      latitude,
      longitude,
      environmentalVariable,
      macroregionOrigin,
      dataInterval,
      timestampStart,
      timestampEnd,
    }: ProbabilisticForecastParams,
    cancelToken?: CancelToken,
    signal?: AbortSignal
  ): Promise<IProbabilisticForecastChart> {
    const result = await api.post(
      "/v1/probabilistic-forecast/probabilistic-forecast-chart",
      {
        latitude,
        longitude,
        environmental_variable: environmentalVariable,
        macro_region_origin: macroregionOrigin,
        data_interval: dataInterval,
        timestamp_start: timestampStart,
        timestamp_end: timestampEnd,
      },
      {
        cancelToken,
        signal,
      }
    );
    return result.data;
  }
}
