import { MacroRegion } from "../../enum/MacroRegion";

export const macroRegionLimits: { [index: string]: number[][][] } = {
  [MacroRegion.ALL_SAINTS_BAY]: [
    [
      [-8.78, -36.45],
      [-8.78, -41.2],
      [-14.95, -41.2],
      [-14.95, -36.45],
      [-8.78, -36.45],
    ],
  ],
  [MacroRegion.PATOS_LAGOON]: [
    [
      [-34.675190535310016, -52.405110714212924],
      [-35.21629315665815, -47.98581065720407],
      [-31.847559269700206, -47.05055460524292],
      [-29.392726521220325, -46.558242013513194],
      [-29.514770057871075, -49.049146386261754],
      [-28.78950472773306, -53.52023093722407],
      [-30.985712829900834, -55.37704973873305],
      [-33.12961502264914, -54.339608558513575],
      [-34.675190535310016, -52.405110714212924],
    ],
  ],
  [MacroRegion.PORT_OF_ACU]: [
    [
      [-21.0, -42.0],
      [-23.38, -42.0],
      [-23.38, -40.0],
      [-21.0, -40.0],
      [-21.0, -42.0],
    ],
  ],
  [MacroRegion.PORT_OF_ITAGUAI]: [
    [
      [-24.901033629484573, -45.371038114512714],
      [-24.901033629484573, -43.511061639298106],
      [-22.27253079949385, -43.511061639298106],
      [-22.27253079949385, -45.371038114512714],
      [-24.901033629484573, -45.371038114512714],
    ],
  ],
  [MacroRegion.PORT_OF_ITAJAI]: [
    [
      [-26.46622540562123, -46.52104471070345],
      [-26.46622540562123, -50.232416871535804],
      [-29.318565778986645, -50.232416871535804],
      [-29.318565778986645, -46.52104471070345],
      [-26.46622540562123, -46.52104471070345],
    ],
  ],
  [MacroRegion.PORT_OF_PARANAGUA]: [
    [
      [-25.992850919303137, -49.44783132882179],
      [-25.992850919303137, -47.53577657121269],
      [-24.97663123173696, -47.53577657121269],
      [-24.97663123173696, -49.44783132882179],
      [-25.992850919303137, -49.44783132882179],
    ],
  ],
  [MacroRegion.PORT_OF_SANTOS]: [
    [
      [-24.91, -49.06],
      [-24.91, -45.38],
      [-23.07, -45.38],
      [-23.07, -49.06],
      [-24.91, -49.06],
    ],
  ],
  [MacroRegion.RIVER_RHINE_GERMANY]: [
    [
      [51.31688050404, 6.3061523437],
      [50.68079714532, 6.6357421875],
      [50.19096776558, 7.1630859374],
      [49.74578130615, 7.6025390625],
      [49.41812070066, 7.8662109375],
      [48.90083790234, 7.7893066406],
      [48.51296552999, 7.5201416015],
      [48.09275716032, 7.3773193359],
      [48.02299832104, 7.9376220703],
      [48.29781249243, 8.4539794921],
      [49.11343354595, 8.8549804687],
      [49.66762782262, 8.9758300781],
      [50.31390041107, 8.7176513671],
      [50.8128770103, 7.8112792968],
      [51.56341232867, 6.8334960937],
      [51.31688050404, 6.3061523437],
    ],
  ],
  [MacroRegion.PORT_OF_ROTTERDAM]: [
    [
      [51.6, 2.5],
      [51.6, 6.0],
      [53.05, 6.0],
      [53.05, 2.5],
      [51.6, 2.5],
    ],
  ],
  [MacroRegion.PORT_OF_PECEM]: [
    [
      [-2.05, -40.95],
      [-5.025, -40.95],
      [-5.025, -37.82],
      [-2.05, -37.82],
      [-2.05, -40.95],
    ],
  ],
  [MacroRegion.PORT_OF_SINES]: [
    [
      [36.8268747, -7.3828125],
      [42.1634034, -7.3608398],
      [42.2285173, -10.788574],
      [36.8268747, -10.788574],
      [36.8268747, -7.3828125],
    ],
  ],
  [MacroRegion.COAST_OF_BELGIUM]: [
    [
      [51.59492822186348, 2.5045136423212],
      [50.77126263643268, 2.5045136423212],
      [50.77126263643268, 4.870696302291805],
      [51.59492822186348, 4.870696302291805],
      [51.59492822186348, 2.5045136423212],
    ],
  ],
  [MacroRegion.GERMAN_NORTH_SEA]: [
    [
      [55.85, 6],
      [54.5, 6],
      [54.5, 9],
      [55.85, 9],
      [55.85, 6],
    ],
  ],
  [MacroRegion.DANISH_NORTH_SEA]: [
    [
      [55.851, 6],
      [55.851, 9],
      [57.1, 9],
      [57.1, 6],
      [55.851, 6],
    ],
  ],
  [MacroRegion.SCOTLAND_NORTH_SEA]: [
    [
      [57.5, -2.75],
      [56.3, -2.75],
      [56.3, -0.5],
      [57.5, -0.5],
      [57.5, -2.75],
    ],
  ],
  [MacroRegion.SOUTHERN_BIGHT_NORTH_SEA]: [
    [
      [53, 5],
      [53, 0.25],
      [50.5, 0.25],
      [50.5, 5],
      [53, 5],
    ],
  ],
  [MacroRegion.IRISH_SEA]: [
    [
      [52.4, -2.7],
      [55.5, -2.7],
      [55.5, -7.2],
      [52.4, -7.2],
      [52.4, -2.7],
    ],
  ],
  [MacroRegion.BALTIC_SEA]: [
    [
      [56.3, 10.8],
      [54.1, 10.8],
      [54.1, 15.9],
      [56.3, 15.9],
      [56.3, 10.8],
    ],
  ],
  [MacroRegion.COAST_OF_ESP_SANTO]: [
    [
      [-20.776100559942563, -41.02242581569641],
      [-20.776100559942563, -39.12197714919273],
      [-19.159141736806234, -39.12197714919273],
      [-19.159141736806234, -41.02242581569641],
      [-20.776100559942563, -41.02242581569641],
    ],
  ],
  [MacroRegion.COAST_OF_ANTOFAGASTA]: [
    [
      [-18.025, -72.15],
      [-26.99, -72.15],
      [-26.99, -69.2],
      [-18.025, -69.2],
      [-18.025, -72.15],
    ],
  ],
  [MacroRegion.COAST_OF_VALPARAISO]: [
    [
      [-37.27, -73.7],
      [-37.27, -70.4],
      [-27, -70.4],
      [-27, -73.7],
      [-37.27, -73.7],
    ],
  ],
  [MacroRegion.BARRA_NORTE]: [
    [
      [2.4524215891347865, -50.79973997453524],
      [-0.6353338040624834, -54.378630521032136],
      [-1.3432829495417309, -60.88441841016892],
      [-4.698519715844242, -60.970789154659684],
      [-4.8224820767031105, -55.140172169139845],
      [-2.0373802513448, -52.90155098595197],
      [-1.0801975275516043, -50.27346546332683],
      [-0.2150521539113015, -48.39762460023695],
      [0.6675479333829912, -47.904558145378786],
      [1.7777206016641571, -47.784469951824605],
      [2.2369355826132136, -48.94947394372704],
      [2.4524215891347865, -50.79973997453524],
    ],
  ],
  [MacroRegion.SAO_FRANCISCO_DO_SUL]: [
    [
      [-26.0, -47.54],
      [-26.0, -50.23],
      [-26.45, -50.23],
      [-26.45, -47.54],
      [-26.0, -47.54],
    ],
  ],
  [MacroRegion.SOUTH_BAHIA]: [
    [
      [-15.0, -40.2],
      [-17.1, -40.2],
      [-17.1, -37.4],
      [-15.0, -37.4],
      [-15.0, -40.2],
    ],
  ],
  [MacroRegion.COAST_OF_ALAGOAS]: [
    [
      [-8.78, -36.44],
      [-11.61, -36.44],
      [-11.61, -34.45],
      [-8.78, -34.45],
      [-8.78, -36.44],
    ],
  ],
  [MacroRegion.COAST_OF_RIO_GRANDE_DO_NORTE]: [
    [
      [-3.67, -37.81],
      [-6.75, -37.81],
      [-6.75, -34.2],
      [-3.67, -34.2],
      [-3.67, -37.81],
    ],
  ],
  [MacroRegion.COAST_OF_PIAUI]: [
    [
      [-5.239, -40.96],
      [-2.045, -40.96],
      [-2.045, -42.89],
      [-5.239, -42.89],
      [-5.239, -40.96],
    ],
  ],
  [MacroRegion.COAST_OF_MARANHAO]: [
    [
      [-5.063, -42.9],
      [-0.645, -42.9],
      [-0.645, -46.145],
      [-5.063, -46.145],
      [-5.063, -42.9],
    ],
  ],
  [MacroRegion.COAST_OF_PARA]: [
    [
      [-2.1405905123274067, -52.770941871560794],
      [-4.052672419893327, -54.33812755173969],
      [-4.7986123489388035, -54.98767708207889],
      [-4.822082611005001, -47.61545922697846],
      [-2.7517473443160583, -46.32678746496455],
      [-0.34579683203558886, -46.2234069983665],
      [1.0124794250577054, -46.21560017078011],
      [1.7234243531479052, -47.73763758397547],
      [0.5021993737096011, -47.88550076987002],
      [-0.23068066794381537, -48.36123449840085],
      [-1.7925945902017872, -51.76851553435361],
      [-2.1405905123274067, -52.770941871560794],
    ],
  ],
  [MacroRegion.COAST_OF_RIO_DE_JANEIRO]: [
    [
      [-24.308435464336, -43.50939808028431],
      [-24.308435464336, -42.0135174104322],
      [-22.238819947362515, -42.0135174104322],
      [-22.238819947362515, -43.50939808028431],
      [-24.308435464336, -43.50939808028431],
    ],
  ],
  [MacroRegion.MATO_GROSSO_DO_SUL]: [
    [
      [-17.89, -56.12],
      [-22.18, -56.12],
      [-22.18, -51.49],
      [-17.89, -51.49],
      [-17.89, -56.12],
    ],
  ],
  [MacroRegion.MATO_GROSSO]: [
    [
      [-11.8, -52.8],
      [-15, -52.8],
      [-15, -50],
      [-11.8, -50],
      [-11.8, -52.8],
    ],
  ],
  [MacroRegion.PERU_NORTE]: [
    [
      [-3.3, -82.4],
      [-9.9, -82.4],
      [-9.9, -77.5],
      [-3.3, -77.5],
      [-3.3, -82.4],
    ],
  ],
  [MacroRegion.PERU_CENTRAL]: [
    [
      [-9.901, -80],
      [-14.65, -80],
      [-14.65, -75],
      [-9.901, -75],
      [-9.901, -80],
    ],
  ],
  [MacroRegion.PERU_SUL]: [
    [
      [-14.655, -76],
      [-18.02, -76],
      [-18.02, -70],
      [-14.655, -70],
      [-14.655, -76],
    ],
  ],
};
