import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { useStores } from "../../../stores/setup/use-store";
import { Box, Progress } from "@chakra-ui/react";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
  useMapEvents,
} from "react-leaflet";
import "./Styles.css";
import { divIcon, LatLngExpression, LeafletMouseEvent } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { IStation } from "../../../stores/data/oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import { EnvironmentalType } from "../../../enum/EnvironmentalType";
import {
  extractCoordinates,
  getCoordinatesWithinRadius,
} from "../../../util/getCoordinatesWithinRadius/getCoordinatesWithinRadius";
import { DateTime } from "luxon";
import useFetchInsightMultiStation from "../../../hooks/useFetchInsightMultiStation";
import { Center, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { roundToNearestHour } from "../../../util/roundHours/roundHours";
import {
  findChartMenuParentId,
  loadChartMenuData,
} from "../ForecastChartPanelEnvironmentalButtons/ChartMenuConfig";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import markerOff from "../../../assets/marker-off.svg";
import markerOffSelected from "../../../assets/marker-off-selected.svg";
import arrowIcon from "assets/marker-icon.svg";
import warningClock from "assets/warning-clock.svg";

import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { SensorMarker } from "../../../components/SensorMarker/SensorMarker";
import { TooltipContent } from "../../../components/TooltipComponent/ToolltipComponent";
import {
  ISensorMeasuredData,
  MeasuredData,
} from "../../../types/ISensorMeasuredData";
import { EnvironmentalVariables } from "../../../enum/EnvironmentalVariables";
import { VisualizationType } from "../../../enum/VisualizationType";
import PulseLoadingMarker from "components/PulseLoadingMarker/PulseLoadingMarker";

interface MapMarkerChildProps {
  station: IStation;
}

const ForecastChartPanelMap: React.FC = () => {
  const {
    dataStores: {
      forecastChartPageStore,
      regionStore,
      forecastChartInsightStore,
      forecastChartMeteogramStore,
      forecastChartProbabilisticStore,
      analyticsChartStore,
    },
  } = useStores();

  useFetchInsightMultiStation(
    forecastChartInsightStore,
    forecastChartPageStore,
    forecastChartMeteogramStore.selectedInsight?.id
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "forecastChart.forecastChartPanelMap",
  });

  const toast = useToast();
  const toastId = "toastId";

  const reloadMultiStationInsight = () => {
    if (
      !forecastChartPageStore.insightMode ||
      !forecastChartMeteogramStore.selectedInsight
    ) {
      return;
    }

    if (!forecastChartInsightStore.insightMultiStation) {
      return;
    }

    const now = DateTime.utc().toMillis();
    const hasOutDatedData =
      forecastChartInsightStore.insightMultiStation.stationsData.find(
        (s) => now - s.calculatedAt > 15 * 60 * 1000
      );

    if (!hasOutDatedData) {
      return;
    }

    if (!toast.isActive(toastId)) {
      toast({
        id: "toastId",
        title: t("insightDataUpdate"),
        description: t("insightDataUpdateDescription"),
        status: "info",
        position: "top",
        isClosable: true,
        duration: null,
      });
    }

    forecastChartInsightStore.setInsightMultiStation(null);
    forecastChartInsightStore.setSelectedCoordinatesWithinRadius([]);
    forecastChartInsightStore.setTimelineDate(null);

    const { station_lat, station_lon } =
      forecastChartPageStore.getStationByIdAndRegionId(
        forecastChartPageStore.selectedStation,
        forecastChartPageStore.regionId
      ) || {};

    if (!station_lat || !station_lon) {
      return;
    }

    const stationCoordinates = {
      latitude: station_lat,
      longitude: station_lon,
    };

    const coordinatesList = getCoordinatesWithinRadius(
      stationCoordinates,
      extractCoordinates(forecastChartPageStore.stations),
      forecastChartInsightStore.radius
    );

    forecastChartInsightStore.setSelectedCoordinatesWithinRadius(
      coordinatesList
    );
    forecastChartMeteogramStore.resetInsightMeteogram();

    const roundedNow = roundToNearestHour(now);
    forecastChartInsightStore.setTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 24 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
    forecastChartInsightStore.setSelectedTimelineDate({
      start: roundedNow.toMillis(),
      end: roundedNow.plus({ hours: 3 }).toMillis(),
      timezoneOffset: regionStore.mainRegionTimezoneOffset!,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(reloadMultiStationInsight, 10 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MapChild = () => {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        map.locate();
        forecastChartMeteogramStore.setOpenMeteogram(true);
        forecastChartMeteogramStore.resetInsightMeteogram();

        forecastChartPageStore.setSelectedStation(0);
        forecastChartPageStore.setClickMapLatLng(e.latlng);
        forecastChartPageStore.setStationInfo();
        forecastChartPageStore.updateMacroRegionOrigin(
          { lat, lng },
          regionStore.macroRegions
        );
        forecastChartPageStore.setActionCallMapClick();
      },
    });

    return <></>;
  };

  const MapMarkerChild: React.FC<MapMarkerChildProps> = (props) => {
    const { station } = props;
    const { station_id, station_name, station_lat, station_lon, region_id } =
      station;

    const isInsightStationEmpty = useMemo(() => {
      const matchingStation =
        forecastChartInsightStore.insightMultiStation?.stationsData.find(
          (data) =>
            data.stationId === station.station_id &&
            data.regionId === station.region_id
        );

      if (matchingStation && !matchingStation.data?.length) {
        return true;
      }

      return false;
    }, [station]);

    const handleMarkerClick = useCallback(
      (e: LeafletMouseEvent) => {
        forecastChartMeteogramStore.setOpenMeteogram(true);
        analyticsChartStore.setIsLastChartDateGreaterThan3Hours(false);

        if (e.latlng === forecastChartPageStore.clickMapLatLng) {
          return;
        }
        forecastChartMeteogramStore.resetInsightMeteogram();
        forecastChartProbabilisticStore.reset();

        forecastChartPageStore.setSelectedStation(station_id);
        forecastChartPageStore.setRegionId(region_id as number);
        forecastChartPageStore.setClickMapLatLng(e.latlng);
        forecastChartPageStore.setStationInfo();
        forecastChartPageStore.updateMacroRegionOrigin(
          e.latlng,
          regionStore.macroRegions
        );
        forecastChartPageStore.setActionCallMapClick();

        const stationCoordinates = {
          latitude: station.station_lat,
          longitude: station.station_lon,
        };

        forecastChartInsightStore.calculateCoordinatesWithinRadius(
          stationCoordinates,
          forecastChartPageStore.stations
        );
      },
      [region_id, station.station_lat, station.station_lon, station_id]
    );

    const stationName = isInsightStationEmpty
      ? `[${t("noInsightData")}] ${station_name}`
      : station_name;

    const sensorItem = forecastChartPageStore.sensorMarkerData?.find(
      (marker) =>
        marker.station_id === station.station_id &&
        marker.region_id === station.region_id
    );

    let directionData: MeasuredData | undefined = undefined;
    let variableData: MeasuredData | undefined = undefined;

    const isSensorTab =
      forecastChartMeteogramStore.visualizationType ===
      VisualizationType.SENSOR;

    if (sensorItem && isSensorTab) {
      const data = getMeasuredDataByActivityTab(sensorItem);
      directionData = data.directionData;
      variableData = data.variableData;
    }

    const isDataDelayed = sensorItem && !variableData && !directionData;

    return (
      <Marker
        position={[station_lat, station_lon]}
        icon={getIconForStation(props.station)}
        eventHandlers={{
          click: handleMarkerClick,
        }}
      >
        <Tooltip>
          <TooltipContent
            zoomLevel={forecastChartPageStore.mapZoom}
            title={stationName}
            value={variableData?.value}
            defaultUnit={variableData?.units}
            degreePosition={directionData?.value}
            lastUpdate={variableData ? new Date(variableData?.date) : undefined}
            environmentalVariable={variableData?.environmental_variable}
            showFooterText={isDataDelayed}
          />
        </Tooltip>
      </Marker>
    );
  };

  const customMarkerIcon = (isSensorTab = false) => {
    return divIcon({
      html: renderToStaticMarkup(
        <i
          style={{ color: `rgb(46, 50, 51, ${isSensorTab ? 0.5 : 1})` }}
          className="fas fa-map-marker-alt fa-1x"
        />
      ),
    });
  };

  const renderPulseLoadingMarker = () => {
    return divIcon({
      html: renderToStaticMarkup(<PulseLoadingMarker />),
    });
  };

  const getEnvironmentalData = (
    measuredData: MeasuredData[],
    directionVar: EnvironmentalVariables,
    valueVar: EnvironmentalVariables
  ) => {
    const directionData = measuredData.find(
      (item) => item.environmental_variable === directionVar
    );
    const variableData = measuredData.find((item) =>
      item.environmental_variable.includes(valueVar)
    );

    return { directionData, variableData };
  };

  const getMeasuredDataByActivityTab = (sensorItem?: ISensorMeasuredData) => {
    const { tabStateActivty } = forecastChartPageStore;
    let environmentalData;
    if (!sensorItem)
      return {
        directionData: undefined,
        variableData: undefined,
      };

    const { measuredData } = sensorItem;

    switch (tabStateActivty) {
      case EnvironmentalType.WEATHER:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
      case EnvironmentalType.WAVE:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WAVE_DIR,
          EnvironmentalVariables.WAVE_HEIGHT
        );
        break;
      case EnvironmentalType.WATER_FLOW:
        if (sensorItem.environmentalType === EnvironmentalType.CURRENT) {
          environmentalData = getEnvironmentalData(
            measuredData,
            EnvironmentalVariables.CURRENT_DIR,
            EnvironmentalVariables.CURRENT_VEL
          );
        } else {
          environmentalData = getEnvironmentalData(
            measuredData,
            EnvironmentalVariables.CURRENT_DIR,
            EnvironmentalVariables.WATER_LEVEL
          );
        }
        break;
      default:
        environmentalData = getEnvironmentalData(
          measuredData,
          EnvironmentalVariables.WIND_DIR,
          EnvironmentalVariables.WIND_VEL
        );
        break;
    }

    return environmentalData;
  };

  const sensorMarkerIcon = (
    sensorItem: ISensorMeasuredData,
    isSelected: boolean
  ) => {
    const { directionData, variableData } =
      getMeasuredDataByActivityTab(sensorItem);

    let icon = !!directionData
      ? arrowIcon
      : !!variableData
      ? undefined
      : warningClock;

    return divIcon({
      html: renderToStaticMarkup(
        <SensorMarker
          degrees={directionData?.value ?? 0}
          value={variableData?.value ?? 0}
          defaultUnit={variableData?.units}
          environmentalVariable={variableData?.environmental_variable}
          isSelected={isSelected}
          markerIcon={icon}
        />
      ),
    });
  };

  const noDataInsightMarker = divIcon({
    html: renderToStaticMarkup(<img src={markerOff} alt="marker off" />),
  });

  const noDataInsightMarkerSelected = divIcon({
    html: renderToStaticMarkup(
      <img src={markerOffSelected} alt="marker off selected" />
    ),
  });

  const customMarkerIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="fas fa-map-marker fa-2x" />
    ),
  });

  const customMarkerUserIconSelected = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#2E3233" }} className="far fa-star fa-1x" />
    ),
  });

  const customMarkerIconWithData = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#1890ff" }} className="fas fa-map-marker-alt fa-1x" />
    ),
  });

  const customMarkerIconSelectedWithData = divIcon({
    html: renderToStaticMarkup(
      <i style={{ color: "#1890ff" }} className="fas fa-map-marker fa-2x" />
    ),
  });

  const getCenterMap = (): LatLngExpression => {
    if (
      forecastChartPageStore.mapCenterLatitude &&
      forecastChartPageStore.mapCenterLongitude
    ) {
      return [
        forecastChartPageStore.mapCenterLatitude,
        forecastChartPageStore.mapCenterLongitude,
      ];
    }
    return [0, 0];
  };

  const getIconForStation = useCallback(
    (station: IStation) => {
      const _data = loadChartMenuData();
      const parentMenu = findChartMenuParentId(
        _data,
        forecastChartPageStore.tabStateActivty
      );

      const sensorItem = forecastChartPageStore.sensorMarkerData?.find(
        (marker) =>
          marker.station_id === station.station_id &&
          marker.region_id === station.region_id
      );

      const isSensorTab =
        forecastChartMeteogramStore.visualizationType ===
        VisualizationType.SENSOR;

      if (forecastChartPageStore.insightMode) {
        const selectedCoordinatesWithinRadiusMapper: Record<number, number> =
          forecastChartInsightStore.selectedCoordinatesWithinRadius.reduce(
            (acc, curr) => {
              return { ...acc, [curr.latitude]: curr.longitude };
            },
            {}
          );

        if (forecastChartInsightStore.loadingInsightMultiStation) {
          const isSameLat =
            station.station_lat in selectedCoordinatesWithinRadiusMapper;
          const isSameLong =
            selectedCoordinatesWithinRadiusMapper[station.station_lat] ===
            station.station_lon;
          if (isSameLat && isSameLong) {
            return renderPulseLoadingMarker();
          }
        }

        const insightStation =
          forecastChartInsightStore.insightMultiStation?.stationsData.find(
            (s) =>
              s.stationId === station.station_id &&
              s.regionId === station.region_id
          );

        const isSelected =
          station.station_id === forecastChartPageStore.selectedStation &&
          station.region_id === forecastChartPageStore.regionId;

        if (!insightStation) {
          if (isSensorTab && !!sensorItem) {
            const isSelected =
              sensorItem.station_id ===
                forecastChartPageStore.selectedStation &&
              sensorItem.region_id === forecastChartPageStore.regionId;

            return sensorMarkerIcon(sensorItem, isSelected);
          }
          if (
            (forecastChartPageStore.tabStateActivty ===
              EnvironmentalType.WAVE ||
              parentMenu === EnvironmentalType.WAVE) &&
            forecastChartPageStore.stationsWithDataPostProcessed.find(
              (stationWithData) =>
                stationWithData.station_id === station.station_id &&
                stationWithData.region_id === station.region_id
            )
          ) {
            if (
              station.station_id === forecastChartPageStore.selectedStation &&
              station.region_id === forecastChartPageStore.regionId
            ) {
              return customMarkerIconSelectedWithData;
            }

            return customMarkerIconWithData;
          } else {
            return customMarkerIcon(isSensorTab);
          }
        }

        if (!insightStation.data?.length) {
          if (isSensorTab && !!sensorItem) {
            const isSelected =
              sensorItem.station_id ===
                forecastChartPageStore.selectedStation &&
              sensorItem.region_id === forecastChartPageStore.regionId;

            return sensorMarkerIcon(sensorItem, isSelected);
          }

          if (isSelected) {
            return noDataInsightMarkerSelected;
          } else {
            return noDataInsightMarker;
          }
        }

        const currentInsightStation =
          forecastChartInsightStore.findClosestInsightStationDate(
            insightStation.data
          );

        if (!currentInsightStation) {
          return customMarkerIcon(isSensorTab);
        }

        const next3hHours = DateTime.fromMillis(
          currentInsightStation.timestamp
        );

        const selectedStartDate =
          forecastChartInsightStore.selectedTimelineDate?.start ||
          currentInsightStation.timestamp;

        const selectedEndDate =
          forecastChartInsightStore.selectedTimelineDate?.end ||
          next3hHours.plus({ hour: 3 }).toMillis();

        const nextHoursInsightStationColor =
          forecastChartInsightStore.getInsightColorByInterval(
            insightStation.data,
            roundToNearestHour(selectedStartDate).toMillis(),
            roundToNearestHour(selectedEndDate).toMillis()
          );

        if (!nextHoursInsightStationColor) {
          return customMarkerIcon(isSensorTab);
        }

        return renderInsightMarker({
          innerColor: currentInsightStation.color,
          outerColor: nextHoursInsightStationColor,
          size: isSelected ? "large" : "small",
          hasSensor: !!sensorItem,
          isSensorTab,
          sensorItem,
        });
      }

      if (isSensorTab && !!sensorItem) {
        const isSelected =
          sensorItem.station_id === forecastChartPageStore.selectedStation &&
          sensorItem.region_id === forecastChartPageStore.regionId;

        return sensorMarkerIcon(sensorItem, isSelected);
      }

      if (
        forecastChartPageStore.stationsWithDataPostProcessed.find(
          (stationWithData) =>
            stationWithData.station_id === station.station_id &&
            stationWithData.region_id === station.region_id
        ) &&
        (forecastChartPageStore.tabStateActivty === EnvironmentalType.WAVE ||
          parentMenu === EnvironmentalType.WAVE)
      ) {
        if (
          station.station_id === forecastChartPageStore.selectedStation &&
          station.region_id === forecastChartPageStore.regionId
        ) {
          return customMarkerIconSelectedWithData;
        }

        return customMarkerIconWithData;
      }

      if (
        station.station_id === forecastChartPageStore.selectedStation &&
        station.region_id === forecastChartPageStore.regionId
      ) {
        return customMarkerIconSelected;
      }

      return customMarkerIcon(isSensorTab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customMarkerIcon,
      customMarkerIconSelected,
      customMarkerIconSelectedWithData,
      customMarkerIconWithData,
      forecastChartInsightStore,
      forecastChartPageStore.insightMode,
      forecastChartPageStore.regionId,
      forecastChartPageStore.selectedStation,
      forecastChartPageStore.stationsWithDataPostProcessed,
      forecastChartPageStore.tabStateActivty,
      noDataInsightMarker,
      noDataInsightMarkerSelected,
      forecastChartInsightStore.selectedTimelineDate,
      forecastChartPageStore.sensorMarkerData,
    ]
  );

  const renderInsightMarker = ({
    innerColor,
    outerColor,
    size,
    isSensorTab,
    sensorItem,
  }: {
    innerColor: string;
    outerColor: string;
    size: "small" | "large";
    hasSensor: boolean;
    isSensorTab: boolean;
    sensorItem?: ISensorMeasuredData | undefined;
  }) => {
    const isLarge = size === "large";

    const { directionData, variableData } =
      getMeasuredDataByActivityTab(sensorItem);

    let icon = !!directionData
      ? arrowIcon
      : !!variableData
      ? undefined
      : warningClock;

    return divIcon({
      html: renderToStaticMarkup(
        <div style={{ position: "relative" }}>
          {isSensorTab && sensorItem && (
            <div style={{ position: "absolute", inset: 0, zIndex: 10 }}>
              <SensorMarker
                degrees={directionData?.value ?? 0}
                value={variableData?.value ?? 0}
                defaultUnit={variableData?.units}
                environmentalVariable={variableData?.environmental_variable}
                isSelected={false}
                markerIcon={icon}
              />
            </div>
          )}

          <span
            className={`fa-stack fa ${size}`}
            style={{ opacity: isSensorTab ? 0.6 : 1 }}
          >
            <i
              style={{ color: outerColor }}
              className={`far fa-circle fa-stack-2x  ${isLarge && "fa-beat"}`}
            />
            <i
              style={{ color: innerColor }}
              className={`fa fa-circle fa-stack-1x fa-inverse ${
                isLarge && "fa-beat"
              }`}
            />
          </span>
        </div>
      ),
    });
  };

  const FlyMapTo = () => {
    const map = useMap();

    useEffect(() => {
      if (forecastChartPageStore.searchedLatLng) {
        map.flyTo(forecastChartPageStore.searchedLatLng, 10);
        forecastChartPageStore.setSearchedLatLng(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastChartPageStore.searchedLatLng]);

    return null;
  };

  const SearchControl = () => {
    const map = useMap();

    const handleSearchLocation = useCallback((e: any) => {
      const { x, y } = e.location;

      forecastChartPageStore.setSelectedStation(0);
      forecastChartPageStore.setClickMapLatLng({
        lat: y,
        lng: x,
      });
      forecastChartPageStore.setStationInfo();
      forecastChartPageStore.updateMacroRegionOrigin(
        { lat: y, lng: x },
        regionStore.macroRegions
      );
    }, []);

    useEffect(() => {
      const provider = new OpenStreetMapProvider();
      //@ts-ignore
      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: customMarkerUserIconSelected,
        },
        searchLabel: t("searchAddress"),
        autoClose: true,
        retainZoomLevel: true,
      });

      searchControl.__proto__.onSubmit = function ({ query, data }: any) {
        const latLongRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;
        const match = query.match(latLongRegex);

        if (match) {
          const [lat, lng] = query.split(",");
          map.flyTo(
            {
              lat,
              lng,
            },
            15
          );
          forecastChartPageStore.setSelectedStation(0);
          forecastChartPageStore.setClickMapLatLng({
            lat,
            lng,
          });
          forecastChartPageStore.setStationInfo();
          forecastChartPageStore.updateMacroRegionOrigin(
            { lat, lng },
            regionStore.macroRegions
          );
        } else if (data) {
          this.showResult(data);
        }
      };

      map.on("geosearch/showlocation", handleSearchLocation);

      map.addControl(searchControl);

      return () => {
        map.removeControl(searchControl);
        map.off("geosearch/showlocation", handleSearchLocation);
      };
    }, [map, handleSearchLocation]);

    useEffect(() => {
      const handleResizeTooltip = () => {
        const zoom = map.getZoom();
        forecastChartPageStore.setMapZoom(zoom);
      };

      map.on("zoom", handleResizeTooltip);
    }, [map]);

    return null;
  };

  return (
    <MapContainer
      key={`mapStation-${forecastChartPageStore.mapCenterLatitude}`}
      center={getCenterMap()}
      zoom={forecastChartPageStore.mapZoom}
      scrollWheelZoom={true}
      className="map"
      attributionControl={false}
      zoomControl={true}
    >
      <FlyMapTo key={`id-${forecastChartPageStore.searchedLatLng}`} />

      <SearchControl />

      <MapChild />

      <TileLayer
        attribution='&copy; <a href="https://www.i4sea.com">i4sea</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      <Center
        hidden={!forecastChartInsightStore.loadingInsightMultiStation}
        bg="#0000005e"
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        top="30%"
        borderRadius="base"
        zIndex={99999}
        py="12px"
        px="16px"
      >
        <Box display="flex" flexDir="column" alignItems="center">
          <Progress
            colorScheme="blue"
            hasStripe={false}
            width="200px"
            size="xs"
            isIndeterminate
          />
          <Text color="white" fontWeight="medium">
            {t("loading")}...
          </Text>
        </Box>
      </Center>

      {forecastChartPageStore.clickMapLatLng &&
        forecastChartPageStore.selectedStation === 0 && (
          <Marker
            position={forecastChartPageStore.clickMapLatLng}
            icon={customMarkerUserIconSelected}
          ></Marker>
        )}

      <div
        key={`${forecastChartPageStore.insightMode} - ${forecastChartInsightStore.insightMultiStation?.id} - ${forecastChartPageStore.tabStateActivty} - ${forecastChartMeteogramStore.visualizationType} - ${forecastChartInsightStore.loadingInsightMultiStation}`}
      >
        {forecastChartPageStore.stations.map((station) => (
          <MapMarkerChild
            key={`${station.station_id}-${station.region_id}`}
            station={station}
          />
        ))}
      </div>
    </MapContainer>
  );
};

export default observer(ForecastChartPanelMap);
